import {
  Text,
  View,
  StyleSheet,
  TouchableOpacity,
  ImageBackground,
  Alert,
} from "react-native";
import React from "react";

const CatItem = (props) => {
  const check_position = () => {
    if (props.latitude != null && props.longitude != null) {
      return true;
    } else {
      return false;
    }
  };

  const getImgUrl = () => {
    switch (props.catId) {
      case "-1":
        return require("../assets/Categories/autour.webp");
      case "1":
        return require("../assets/Categories/acorbranches.webp");
      case "2":
        return require("../assets/Categories/bateau.webp");
      case "3":
        return require("../assets/Categories/bien-etre.webp");
      case "4":
        return require("../assets/Categories/bowling.webp");
      case "5":
        return require("../assets/Categories/chateaux-jardins.webp");
      case "6":
        return require("../assets/Categories/cinema.webp");
      case "7":
        return require("../assets/Categories/conduite-circuit.webp");
      case "8":
        return require("../assets/Categories/cours.webp");
      case "9":
        return require("../assets/Categories/air.webp");
      case "10":
        return require("../assets/Categories/evenement.webp");
      case "11":
        return require("../assets/Categories/jeux-divertissements.webp");
      case "12":
        return require("../assets/Categories/musee.webp");
      case "13":
        return require("../assets/Categories/paintball.webp");
      case "14":
        return require("../assets/Categories/parc-aquatique.webp");
      case "15":
        return require("../assets/Categories/patin.webp");
      case "16":
        return require("../assets/Categories/plein-air.webp");
      case "17":
        return require("../assets/Categories/rando-equestre.webp");
      case "18":
        return require("../assets/Categories/shopping.webp");
      case "19":
        return require("../assets/Categories/skatepark.webp");
      case "20":
        return require("../assets/Categories/ski.webp");
      case "21":
        return require("../assets/Categories/soccer.webp");
      case "22":
        return require("../assets/Categories/sortir.webp");
      case "23":
        return require("../assets/Categories/sur-eau.webp");
      case "24":
        return require("../assets/Categories/zoo.webp");
      case "25":
        return require("../assets/Categories/golf.webp");
      case "26":
        return require("../assets/Categories/non-classe.webp");
      case "28":
        return require("../assets/Categories/escape.webp");
      case "29":
        return require("../assets/Categories/escalade.webp");
    }
  };

  const renderItem = () => {
    if (check_position()) {
      return (
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              props.navigation.navigate("Activities", {
                categoryName: props.nom,
                categoryId: props.catId,
                latitude: props.latitude,
                longitude: props.longitude,
                city: props.city,
              });
            }}
          >
            <ImageBackground source={getImgUrl()} style={styles.image}>
              <Text style={styles.text}>{props.nom}</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
      );
    } else {
      return (
        <View style={styles.container}>
          <TouchableOpacity
            style={styles.button}
            onPress={() => {
              Alert.alert(
                "Veuillez saisir une ville ou accepté la géolocalisation"
              );
            }}
          >
            <ImageBackground source={getImgUrl()} style={styles.image}>
              <Text style={styles.text}>{props.nom}</Text>
            </ImageBackground>
          </TouchableOpacity>
        </View>
      );
    }
  };
  return renderItem();
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  button: {
    flex: 1,
  },
  text: {
    width: "100%",
    height: "100%",
    textAlign: "center",
    color: "#FFFFFF",
    textAlignVertical: "center",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    padding: 50,
  },
  image: {
    flex: 1,
    justifyContent: "center",
  },
});

export default CatItem;
