import {
  Text,
  View,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
} from "react-native";
import React from "react";

const PubSlot = () => {
  const openWebsite = async (link) => {
    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <View style={{ margin: 5 }}>
      <Text style={{ fontWeight: "bold" }}>Annonce :</Text>
      <View style={{ flexDirection: "row" }}>
        <Image
          style={styles.image_description}
          source={{
            uri: "https://www.izibikes.com/wp-content/uploads/2021/05/AdobeStock_54105059-scaled-e1620119265305.jpeg",
          }}
        />
        <View style={{ flex: 1, paddingHorizontal: 10 }}>
          <Image
            style={styles.image_logo}
            source={{
              uri: "https://www.izibikes.com/wp-content/uploads/2021/05/izibike-vert-scaled-e1620240857516.jpg",
            }}
          />
          <View style={{ margin: 5 }}></View>
          <Text style={styles.titre}>LOCATION DE VÉLOS ÉLECTRIQUES</Text>
          <View style={{ margin: 5 }}></View>
          <TouchableOpacity
            style={styles.btn}
            onPress={() =>
              openWebsite(
                "https://www.izibikes.com/?utm_source=Proxifun&utm_medium=Appli&utm_campaign=PubHeader"
              )
            }
          >
            <Text style={{ alignSelf: "center", color: "#FFFFFF" }}>
              RESERVER
            </Text>
          </TouchableOpacity>
        </View>
      </View>
      <View style={{ margin: 5 }}></View>
    </View>
  );
};

const styles = StyleSheet.create({
  image_logo: {
    height: 30,
    width: 100,
    alignSelf: "center",
  },
  image_description: {
    height: 90,
    width: 200,
    alignSelf: "center",
  },
  titre: {
    fontWeight: "bold",
    fontSize: 12,
  },
  btn: {
    borderRadius: 3,
    backgroundColor: "#27B776",
  },
});

export default PubSlot;
