import {
  Text,
  View,
  StatusBar,
  TextInput,
  TouchableOpacity,
  Alert,
  ScrollView,
  Image,
  Platform,
  SafeAreaView,
} from "react-native";

import React, { useState, useEffect } from "react";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import AwesomeAlert from "react-native-awesome-alerts";
import RadioForm from "react-native-simple-radio-button";
import Item from "../components/Item";
import * as Location from "expo-location";
import * as ImagePicker from "expo-image-picker";
import Axios from "../Utils/Axios";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import { Picker } from "@react-native-picker/picker";

const FormData = global.FormData;

var radio_props = [
  { label: "Non", value: 0 },
  { label: "Oui", value: 1 },
];

const Edit = (props) => {
  const [searchId, setSearchId] = useState(null);
  const [searchName, setSearchName] = useState(null);
  const [category, setCategory] = useState(null);
  const [data, setData] = useState(null);

  const [id, setId] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.id
  );
  const [nom, setNom] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.nom
  );
  const [adress, setAdress] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.adress
  );
  const [latitude, setLatitude] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.latitude
  );
  const [longitude, setLongitude] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.longitude
  );
  const [telephone, setTelephone] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.telephone
  );
  const [mail, setMail] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.mail
  );
  const [content, setContent] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.content
  );
  const [website, setWebsite] = useState(
    props.route.params.type_edit == 1 ? null : props.route.params.website
  );
  const [picture, setPicture] = useState(null);
  const [highlighted, setHighlighted] = useState(
    props.route.params.type_edit == 1 ? 0 : props.route.params.highlighted
  );
  const [loading, setLoading] = useState(false);
  const [suppAlert, setSuppAlert] = useState(false);
  const [alert, setAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState(null);
  const [msgAlert, setMsgAlert] = useState(null);
  const [search, setSearch] = useState(false);
  const [typeSearch, setTypeSearch] = useState(false);
  const [showItem, setShowItem] = useState(true);
  const [image, setImage] = useState(null);
  const [genere, setGenere] = useState(false);

  function showMsg(title, message) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setAlert(true);
    }
  }

  function showSuppMsg(title, message) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message, [
        {
          text: "Non",
          onPress: () => null,
          style: "cancel",
        },
        { text: "Oui", onPress: () => deleteActivity(id) },
      ]);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setSuppAlert(true);
    }
  }

  function createActivity(json) {
    //console.log(json.items[0]["id"]);
    if (json.items != null) {
      setId(json.items[0]["id"]);
      setNom(json.items[0]["title"]);
      setTelephone(json.items[0]["phoneNumber"]);
      setMail(json.items[0]["email"]);
      setAdress(json.items[0]["address"]);
      setLatitude(json.items[0]["latitude"]);
      setLongitude(json.items[0]["longitude"]);
      setContent(json.items[0]["content"]);
      setWebsite(json.items[0]["website"]);
      setHighlighted(json.items[0]["highlighted"]);
      setPicture(json.items[0]["picture"]);
      //console.log(id);
      setSearch(true);
      setShowItem(true);
    } else {
      setSearch(false);
    }
  }

  function searchActivityBy(type) {
    setImage(null);
    const formData = new FormData();
    formData.append("type", type);
    formData.append("id", searchId);
    formData.append("nom", searchName);

    const config = {
      method: "post",
      url: "/php/search",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    setLoading(true);
    Axios()
      .request(config)
      .then(function (response) {
        if (!response.data.items) {
          setLoading(false);
          setSearch(false);
          if (type == 1) {
            showMsg(
              "Erreur lors de la recherche",
              "Aucune activité trouvée avec cet id"
            );
          } else if (type == 2) {
            showMsg(
              "Erreur lors de la recherche",
              "Aucune activité trouvée avec ce nom"
            );
          }
        } else {
          createActivity(response.data);
        }
      })
      .catch(function (error) {
        // handle error
        //console.log(error);
        if (type == 1) {
          showMsg(
            "Erreur lors de la recherche",
            "Aucune activité trouvée avec cet id"
          );
        } else if (type == 2) {
          showMsg(
            "Erreur lors de la recherche",
            "Aucune activité trouvée avec ce nom"
          );
        }
      })
      .then(function () {
        setLoading(false);
      });
  }

  function deleteActivity(idActivityToDelete) {
    const formData = new FormData();
    formData.append("id", idActivityToDelete);

    const config = {
      method: "post",
      url: "/php/delete",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    setLoading(true);
    Axios()
      .request(config)
      .then(function (response) {
        if (response.data) {
          setLoading(false);
          setSearch(false);
          showMsg(
            "Suppression réussie",
            "L'activité a été supprimée avec succès"
          );
        } else {
          setLoading(false);
          setSearch(false);
          showMsg(
            "Erreur lors de la suppression",
            "Une erreur est survenue lors de la suppression de l'activité"
          );
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  }

  function updateActivity() {
    /*console.log(id);
    console.log(nom);
    console.log(adress);
    console.log(latitude);
    console.log(longitude);
    console.log(telephone);
    console.log(mail);
    console.log(content);
    console.log(website);
    console.log(highlighted);*/
    const formData = new FormData();
    formData.append("id", id);
    formData.append("name", nom);
    formData.append("adress", adress);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("phone", telephone);
    formData.append("mail", mail);
    formData.append("content", content);
    formData.append("website", website);
    formData.append("highlighted", highlighted);

    const config = {
      method: "post",
      url: "/php/update",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    setLoading(true);
    Axios()
      .request(config)
      .then(function (response) {
        if (response.data) {
          showMsg(
            "Mise à jour réussie",
            "L'activité a été mise à jour avec succès"
          );
        } else {
          showMsg(
            "Erreure lors de la mise à jour",
            "Une erreur s'est produite lors de la mise à jour"
          );
        }
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .then(function () {
        setLoading(false);
        setSearch(false);
      });
  }

  const uploadDocument = (imagedata) => {
    //console.log(imagedata);
    let data = {
      method: "POST",
      body: JSON.stringify({ image: imagedata }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let targetUrl = "";
    if (Platform.OS === "android" || Platform.OS === "ios") {
      targetUrl = "https://www.app.proxifun.com/php/upload";
    } else {
      targetUrl = "/php/upload";
    }
    fetch(targetUrl, data)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
      });
  };

  const pickImage = async () => {
    let options = {
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      //allowsEditing: true,
      //aspect: [4, 3],
      //quality: 1,
      base64: true,
    };
    if (nom != null) {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync(options);

      if (!result.cancelled) {
        result.title = nom;
        result.id = id;
        //console.log(result.base64);
        setImage(result);
        uploadDocument(result);
      }
    } else {
      showMsg(
        "Erreur lors du chargement de la photo",
        "Vous devez d'abord entrer le nom de l'activité"
      );
    }
  };

  const getCoords = async () => {
    if (adress != null) {
      try {
        let coords = await Location.geocodeAsync(adress);
        let lat = coords[0].latitude.toFixed(5);
        let long = coords[0].longitude.toFixed(5);
        setLatitude(lat);
        setLongitude(long);
        setGenere(true);
      } catch (error) {
        setGenere(false);
        showMsg(
          "Erreur lors de la génération des coordonnées GPS",
          "Veuillez les saisir manuellement ou entrer un adresse valide"
        );
      }
    } else {
      setGenere(false);
      showMsg(
        "Erreur lors de la génération des coordonnées GPS",
        "Vous devez spécifier l'adresse pour générer les coordonnées GPS"
      );
    }
  };

  function menuButton() {
    return (
      <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            setTypeSearch(1);
            setShowItem(false);
            setSearch(false);
          }}
        >
          <Text style={styles.text_btn}>Id</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            setTypeSearch(2);
            setShowItem(false);
            setSearch(false);
          }}
        >
          <Text style={styles.text_btn}>Nom</Text>
        </TouchableOpacity>
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            setTypeSearch(3);
            setShowItem(false);
            setSearch(false);
          }}
        >
          <Text style={styles.text_btn}>Catégorie</Text>
        </TouchableOpacity>
      </View>
    );
  }

  const getCoordsAct = (searchedCity) => {
    if (searchedCity != null) {
      setLoading(true);
      //console.log(searchedCity);
      Location.geocodeAsync(searchedCity)
        .then((coords) => {
          let lat = coords[0].latitude.toFixed(5);
          let long = coords[0].longitude.toFixed(5);
          //console.log(coords);
          //console.log("long : ", longitudeCity);
          searchAct(lat, long);
        })
        .catch((error) => console.log(error));
    }
  };

  const searchAct = (latitude, longitude) => {
    setImage(null);
    const formData = new FormData();
    if (category == null || category == 0) {
      formData.append("category", "-1");
    } else {
      formData.append("category", category);
    }
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    const config = {
      method: "post",
      url: "/php/act",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };

    Axios()
      .request(config)
      .then(function (response) {
        //console.log(response.data.items);
        setData(response.data.items);
        setSearch(true);
        setShowItem(true);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  function showSearchInput() {
    if (typeSearch == 1) {
      return (
        <View>
          <View style={styles.separator} />
          <Text>Rechercher par id : </Text>
          <TextInput
            style={styles.input}
            onChangeText={setSearchId}
            placeholder=""
            keyboardType="numeric"
          />
          <TouchableOpacity
            style={styles.btn}
            onPress={() => searchActivityBy(1)}
          >
            <Text style={styles.text_btn}>Rechercher</Text>
          </TouchableOpacity>
          <AwesomeAlert
            show={alert}
            showProgress={false}
            title={titleAlert}
            message={msgAlert}
            closeOnTouchOutside={true}
            showConfirmButton={true}
            confirmText="Ok"
            confirmButtonColor="#e70181"
            onConfirmPressed={() => {
              setAlert(false);
            }}
          />
        </View>
      );
    } else if (typeSearch == 2) {
      return (
        <View>
          <View style={styles.separator} />
          <Text>Rechercher par nom : </Text>
          <TextInput
            style={styles.input}
            onChangeText={setSearchName}
            placeholder=""
          />
          <TouchableOpacity
            style={styles.btn}
            onPress={() => searchActivityBy(2)}
          >
            <Text style={styles.text_btn}>Rechercher</Text>
          </TouchableOpacity>
          <AwesomeAlert
            show={alert}
            showProgress={false}
            title={titleAlert}
            message={msgAlert}
            closeOnTouchOutside={true}
            showConfirmButton={true}
            confirmText="Ok"
            confirmButtonColor="#e70181"
            onConfirmPressed={() => {
              setAlert(false);
            }}
          />
        </View>
      );
    } else if (typeSearch == 3) {
      return (
        <View style={styles.margin}>
          <View style={styles.separator} />
          <View style={styles.picker}>
            <Picker
              selectedValue={category}
              style={styles.picker}
              mode={"dialog"}
              onValueChange={(itemValue) => setCategory(itemValue)}
            >
              <Picker.Item label="Toute catégorie" value="-1" />
              <Picker.Item label="Accrobranches" value="1" />
              <Picker.Item label="Balade en bateau" value="2" />
              <Picker.Item label="Bien être" value="3" />
              <Picker.Item label="Bowlings" value="4" />
              <Picker.Item label="Châteaux et jardins" value="5" />
              <Picker.Item label="Cinéma" value="6" />
              <Picker.Item label="Conduite et circuits" value="7" />
              <Picker.Item label="Cours et ateliers divers" value="8" />
              <Picker.Item label="Dans les airs" value="9" />
              <Picker.Item label="Evènements" value="10" />
              <Picker.Item label="Jeux et divertissements" value="11" />
              <Picker.Item label="Musées et visites divers" value="12" />
              <Picker.Item label="Paintball et laser" value="13" />
              <Picker.Item label="Parcs aquatiques et attractions" value="14" />
              <Picker.Item label="Patinoires" value="15" />
              <Picker.Item label="Plein air" value="16" />
              <Picker.Item label="Randonnées équestres" value="17" />
              <Picker.Item label="Shopping" value="18" />
              <Picker.Item label="Skatepark" value="19" />
              <Picker.Item label="Ski" value="20" />
              <Picker.Item label="Football" value="21" />
              <Picker.Item label="Sortir" value="22" />
              <Picker.Item label="Sur l'eau" value="23" />
              <Picker.Item label="Zoo et aquarium" value="24" />
              <Picker.Item label="Golf" value="25" />
              <Picker.Item label="Non classés" value="26" />
              <Picker.Item label="Escape Game" value="28" />
              <Picker.Item label="Escalade" value="29" />
            </Picker>
          </View>
          <View style={styles.XSseparator} />
          <Text>Ville :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setAdress}
            placeholder=" Capbreton"
          />
          <View style={styles.XSseparator} />
          <TouchableOpacity
            style={styles.submit_btn}
            onPress={() => getCoordsAct(adress)}
          >
            <Text style={styles.text_btn}>Rechercher</Text>
          </TouchableOpacity>
        </View>
      );
    }
  }

  const renderEdit = () => {
    // 1 => page édition de l'admin
    // Autre => page édition d'un utilisateur
    if (props.route.params.type_edit == 1) {
      return (
        <>
          <Text
            style={{ alignSelf: "center", fontWeight: "bold", fontSize: 16 }}
          >
            Modifier / Supprimer
          </Text>
          <View style={styles.separator} />
          {menuButton()}
          {showSearchInput()}
          {loading ? (
            <ActivityIndicatorElement />
          ) : search ? (
            showItem ? (
              typeSearch != 3 ? (
                <TouchableOpacity onPress={() => setShowItem(false)}>
                  <Item
                    id={id}
                    titre={nom}
                    adresse={adress}
                    telephone={telephone}
                    content={content}
                    website={website}
                    imageurl={picture}
                    navigation={props.navigation}
                  />
                </TouchableOpacity>
              ) : data != null ? (
                data.map((item) => (
                  <View>
                    <TouchableOpacity
                      onPress={() => {
                        setId(item.id);
                        setNom(item.title);
                        setTelephone(item.phoneNumber);
                        setMail(item.email);
                        setAdress(item.address);
                        setLatitude(item.latitude);
                        setLongitude(item.longitude);
                        setContent(item.content);
                        setWebsite(item.website);
                        setHighlighted(item.highlighted);
                        setPicture(item.picture);
                        setSearch(true);
                        setShowItem(false);
                      }}
                    >
                      <Item
                        key={item.id}
                        titre={item.title}
                        distance={item.distance}
                        adresse={item.address}
                        telephone={item.phoneNumber}
                        content={item.content}
                        imageurl={item.picture}
                        website={item.website}
                        navigation={props.navigation}
                      />
                    </TouchableOpacity>
                  </View>
                ))
              ) : (
                <Text>Aucun resultat</Text>
              )
            ) : (
              <View>
                <View style={styles.separator} />
                <Text>Id : {id}</Text>
                <View style={styles.separator} />
                <Text>Nom :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setNom}
                  value={nom}
                />
                <Text>Téléphone :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setTelephone}
                  value={telephone}
                />
                <Text>Mail :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setMail}
                  value={mail}
                />
                <Text>Adresse :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setAdress}
                  value={adress}
                />
                <TouchableOpacity
                  style={styles.submit_btn}
                  onPress={() => getCoords()}
                >
                  <Text style={styles.text_btn}>
                    Générer les coordonnées GPS
                  </Text>
                </TouchableOpacity>
                <TouchableOpacity
                  style={styles.submit_btn}
                  onPress={() => setGenere(false)}
                >
                  <Text style={styles.text_btn}>
                    Saisir les coordonnées manuellement
                  </Text>
                </TouchableOpacity>
                {genere ? (
                  <View>
                    <View style={{ margin: 10 }} />
                    <Text>Latitude : {latitude}</Text>
                    <View style={{ margin: 10 }} />
                    <Text>Longitude : {longitude}</Text>
                    <View style={{ margin: 10 }} />
                  </View>
                ) : (
                  <>
                    <Text>Latitude :</Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setLatitude}
                      value={latitude}
                    />
                    <Text>Longitude :</Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setLongitude}
                      value={longitude}
                    />
                  </>
                )}
                <Text>Description :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setContent}
                  value={content}
                />
                <Text>Site web :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setWebsite}
                  value={website}
                />
                <Text>Recommandé :</Text>
                <RadioForm
                  style={styles.radio_btn}
                  radio_props={radio_props}
                  initial={highlighted}
                  labelHorizontal={false}
                  formHorizontal={true}
                  onPress={(value) => {
                    setHighlighted(value);
                  }}
                />
                <TouchableOpacity onPress={pickImage} style={styles.btn}>
                  <Text style={styles.text_btn}>Charger une image</Text>
                </TouchableOpacity>
                <View style={styles.separator} />
                {image && (
                  <Image
                    source={{ uri: image.uri }}
                    style={{ width: 300, height: 200, alignSelf: "center" }}
                  />
                )}
                <View style={styles.separator} />
                <View
                  style={{
                    flexDirection: "row",
                    justifyContent: "space-around",
                  }}
                >
                  <TouchableOpacity
                    style={styles.btn}
                    onPress={() =>
                      showSuppMsg(
                        "Supprimer",
                        "Etes vous sur de vouloir supprimer l'activité ?"
                      )
                    }
                  >
                    <Text style={styles.supp_txt}>Supprimer</Text>
                  </TouchableOpacity>
                  <TouchableOpacity onPress={() => updateActivity()}>
                    <Text style={styles.text_btn}>Modifier</Text>
                  </TouchableOpacity>
                </View>
                <AwesomeAlert
                  show={alert}
                  showProgress={false}
                  title={titleAlert}
                  message={msgAlert}
                  closeOnTouchOutside={true}
                  showConfirmButton={true}
                  confirmText="Ok"
                  confirmButtonColor="#e70181"
                  onConfirmPressed={() => {
                    setAlert(false);
                  }}
                />
                <AwesomeAlert
                  show={suppAlert}
                  showProgress={false}
                  title={titleAlert}
                  message={msgAlert}
                  closeOnTouchOutside={true}
                  showConfirmButton={true}
                  showCancelButton={true}
                  confirmText="Oui"
                  cancelText="Non"
                  confirmButtonColor="#e70181"
                  onCancelPressed={() => {
                    setSuppAlert(false);
                  }}
                  onConfirmPressed={() => {
                    setSuppAlert(false);
                    deleteActivity(id);
                  }}
                />
                <View style={{ margin: 100 }} />
              </View>
            )
          ) : null}
        </>
      );
    } else {
      return (
        <ScrollView style={styles.container}>
          {loading ? (
            <ActivityIndicatorElement />
          ) : (
            <View>
              <ScrollView style={styles.container}>
                <Text>Nom :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setNom}
                  value={nom}
                />
                <Text>Téléphone :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setTelephone}
                  value={telephone}
                />
                <Text>Mail :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setMail}
                  value={mail}
                />
                <Text>Adresse :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setAdress}
                  value={adress}
                />
                <TouchableOpacity
                  style={styles.btn}
                  onPress={() => getCoords()}
                >
                  <Text style={styles.text_btn}>
                    Générer les coordonnées GPS
                  </Text>
                </TouchableOpacity>
                <View style={styles.separator} />
                <TouchableOpacity
                  style={styles.btn}
                  onPress={() => setGenere(false)}
                >
                  <Text style={styles.text_btn}>
                    Saisir les coordonnées manuellement
                  </Text>
                </TouchableOpacity>
                {genere ? (
                  <View>
                    <View style={{ margin: 10 }} />
                    <Text>Latitude : {latitude}</Text>
                    <View style={{ margin: 10 }} />
                    <Text>Longitude : {longitude}</Text>
                    <View style={{ margin: 10 }} />
                  </View>
                ) : (
                  <>
                    <Text>Latitude :</Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setLatitude}
                      value={props.route.params.latitude}
                    />
                    <Text>Longitude :</Text>
                    <TextInput
                      style={styles.input}
                      onChangeText={setLongitude}
                      value={props.route.params.longitude}
                    />
                  </>
                )}
                <Text>Description :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setContent}
                  value={props.route.params.content}
                />
                <Text>Site web :</Text>
                <TextInput
                  style={styles.input}
                  onChangeText={setWebsite}
                  value={props.route.params.website}
                />
                <TouchableOpacity onPress={pickImage} style={styles.image_btn}>
                  <Text style={styles.text_btn}>Charger une image</Text>
                </TouchableOpacity>
                <View style={styles.separator} />
                {image && (
                  <Image
                    source={{ uri: image.uri }}
                    style={{ width: 300, height: 200, alignSelf: "center" }}
                  />
                )}
                <View style={styles.footer_btn}>
                  <TouchableOpacity
                    style={styles.btn}
                    onPress={() => updateActivity()}
                  >
                    <Text style={styles.text_btn}>Modifier</Text>
                  </TouchableOpacity>
                </View>
                <AwesomeAlert
                  show={alert}
                  showProgress={false}
                  title={titleAlert}
                  message={msgAlert}
                  closeOnTouchOutside={true}
                  showConfirmButton={true}
                  confirmText="Ok"
                  confirmButtonColor="#e70181"
                  onConfirmPressed={() => {
                    setAlert(false);
                  }}
                />
                <View style={{ margin: 100 }} />
              </ScrollView>
            </View>
          )}
        </ScrollView>
      );
    }
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <ScrollView
        style={styles.main_container}
        showsVerticalScrollIndicator={false}
      >
        <View style={SubContainerStyle()}>
          <View style={styles.margin}>{renderEdit()}</View>
          <View style={styles.separator} />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Edit;
