import {
  Text,
  View,
  FlatList,
  TextInput,
  TouchableOpacity,
  Alert,
  SafeAreaView,
  StatusBar,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import * as Location from "expo-location";
import Item from "../components/Item";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import AwesomeAlert from "react-native-awesome-alerts";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import Axios from "../Utils/Axios";
import FlatListItemSeparator from "../components/FlatListSeparator";
import CustomHeader from "../components/CustomHeader";
import { Picker } from "@react-native-picker/picker";

const TakeActivity = (props) => {
  const [category, setCategory] = useState(-1);
  const [city, setCity] = useState(null);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showItem, setShowItem] = useState(false);
  const [alert, setAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState(null);
  const [msgAlert, setMsgAlert] = useState(null);
  const [targetId, setTargetId] = useState(0);
  const [upUserAccount, setUpUserAccount] = useState(false);

  useEffect(() => {
    //console.log("targetId to change : ", targetId);
    if (upUserAccount) {
      updateUserAccount(targetId);
    }
  }, [upUserAccount]);

  useEffect(() => {
    Location.setGoogleApiKey("AIzaSyD2QFnwIP64KIaG9mKpIMnwkqpX3fiKtZQ");
  }, []);

  const getCoords = (searchedCity) => {
    if (city != null) {
      setLoading(true);
      //console.log(searchedCity);
      Location.geocodeAsync(searchedCity)
        .then((coords) => {
          let lat = coords[0].latitude.toFixed(5);
          let long = coords[0].longitude.toFixed(5);
          //console.log(coords);
          //console.log("long : ", longitudeCity);
          search(lat, long);
        })
        .catch((error) => console.log(error));
    }
  };

  const search = (latitude, longitude) => {
    const formData = new FormData();
    formData.append("category", category);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    const config = {
      method: "post",
      url: "/php/act",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };

    Axios()
      .request(config)
      .then(function (response) {
        //console.log(response.data.items);
        setData(response.data.items);
        setShowItem(true);
        setLoading(false);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      });
  };

  function showMsg(title, message) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message, [
        {
          text: "Non",
          onPress: () => null,
          style: "cancel",
        },
        {
          text: "Oui",
          onPress: () => {
            setLoading(true);
            setUpUserAccount(true);
          },
        },
      ]);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setAlert(true);
    }
  }

  const confirmActivity = (title) => {
    //console.log(targetId);
    showMsg(
      "Confirmation",
      "Êtes-vous sur de vouloir affilier " + title + " à votre compte ?"
    );
  };

  function updateUserAccount(id) {
    if (id != 0) {
      const formData = new FormData();
      formData.append("activity_id", id);
      formData.append("user_id", props.route.params.user_id);
      const config = {
        method: "post",
        url: "/php/updateUserActivity",
        responseType: "json",
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
      };

      Axios()
        .request(config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data) {
            props.navigation.navigate("DashBoard", {
              activity_id: id,
              user_name: props.route.params.user_name,
              user_id: props.route.params.user_id,
            });
          }
          //console.log("Reponse ", response.data);
        })
        .catch(function (error) {
          console.log(error);
        });
    } else {
      console.log("target id null");
    }
  }

  const renderSearch = () => {
    return (
      <View style={styles.margin}>
        <View style={styles.XSseparator} />
        <Text>Veuillez sélectionner l'activité que vous souhaitez manager</Text>
        <View style={styles.separator} />
        <View style={styles.picker}>
          <Picker
            selectedValue={category}
            style={styles.picker}
            mode={"dialog"}
            onValueChange={(itemValue) => setCategory(itemValue)}
          >
            <Picker.Item label="Toute catégorie" value="-1" />
            <Picker.Item label="Accrobranches" value="1" />
            <Picker.Item label="Balade en bateau" value="2" />
            <Picker.Item label="Bien être" value="3" />
            <Picker.Item label="Bowlings" value="4" />
            <Picker.Item label="Châteaux et jardins" value="5" />
            <Picker.Item label="Cinéma" value="6" />
            <Picker.Item label="Conduite et circuits" value="7" />
            <Picker.Item label="Cours et ateliers divers" value="8" />
            <Picker.Item label="Dans les airs" value="9" />
            <Picker.Item label="Evènements" value="10" />
            <Picker.Item label="Jeux et divertissements" value="11" />
            <Picker.Item label="Musées et visites divers" value="12" />
            <Picker.Item label="Paintball et laser" value="13" />
            <Picker.Item label="Parcs aquatiques et attractions" value="14" />
            <Picker.Item label="Patinoires" value="15" />
            <Picker.Item label="Plein air" value="16" />
            <Picker.Item label="Randonnées équestres" value="17" />
            <Picker.Item label="Shopping" value="18" />
            <Picker.Item label="Skatepark" value="19" />
            <Picker.Item label="Ski" value="20" />
            <Picker.Item label="Football" value="21" />
            <Picker.Item label="Sortir" value="22" />
            <Picker.Item label="Sur l'eau" value="23" />
            <Picker.Item label="Zoo et aquarium" value="24" />
            <Picker.Item label="Golf" value="25" />
            <Picker.Item label="Non classés" value="26" />
            <Picker.Item label="Escape Game" value="28" />
            <Picker.Item label="Escalade" value="29" />
          </Picker>
        </View>
        <View style={styles.XSseparator} />
        <Text>Ville :</Text>
        <TextInput
          style={styles.input}
          onChangeText={setCity}
          placeholder=" Capbreton"
        />
        <View style={styles.XSseparator} />
        <TouchableOpacity
          style={styles.submit_btn}
          onPress={() => getCoords(city)}
        >
          <Text style={styles.text_btn}>Rechercher</Text>
        </TouchableOpacity>
        {renderActivities()}
      </View>
    );
  };

  const renderActivities = () => {
    if (loading) {
      return <ActivityIndicatorElement />;
    } else if (showItem) {
      return (
        <>
          <FlatList
            showsVerticalScrollIndicator={false}
            data={data}
            keyExtractor={({ id }) => id}
            renderItem={({ item }) => (
              <View>
                <TouchableOpacity
                  onPress={() => {
                    setTargetId(item.id);
                    confirmActivity(item.title);
                  }}
                >
                  <Item
                    id={item.id}
                    titre={item.title}
                    distance={item.distance}
                    adresse={item.address}
                    telephone={item.phoneNumber}
                    content={item.content}
                    imageurl={item.picture}
                    website={item.website}
                    navigation={props.navigation}
                  />
                </TouchableOpacity>
              </View>
            )}
            ItemSeparatorComponent={() => FlatListItemSeparator()}
          />
          <AwesomeAlert
            show={alert}
            showProgress={false}
            title={titleAlert}
            message={msgAlert}
            closeOnTouchOutside={true}
            showConfirmButton={true}
            showCancelButton={true}
            confirmText="Oui"
            cancelText="Non"
            confirmButtonColor="#e70181"
            onCancelPressed={() => {
              setAlert(false);
            }}
            onConfirmPressed={() => {
              setUpUserAccount(true);
              setAlert(false);
            }}
          />
        </>
      );
    }
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderSearch()}</View>
    </SafeAreaView>
  );
};

export default TakeActivity;
