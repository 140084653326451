import { Platform } from "react-native";
const CustomHaderStyle = () => {
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return {
      paddingTop: 10,
      paddingBottom: 10,
      flexDirection: "row",
      backgroundColor: "#e70181",
    };
  } else {
    return {
      padding: 15,
      flexDirection: "row",
      backgroundColor: "#e70181",
    };
  }
};

export default CustomHaderStyle;
