import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import React from "react";

const HighlightedItem = (props) => {
  const openMap = async () => {
    const provider = Platform.OS === "ios" ? "apple" : "google";
    const link = `http://maps.${provider}.com/?daddr=${props.titre} ${props.adresse}`;

    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log(error);
    }
  };

  const check_website = () => {
    if (props.website.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const check_content = () => {
    if (typeof props.content !== "undefined" && props.content.length > 0) {
      if (
        props.content[0] != "V" &&
        props.content[0] != "<" &&
        props.content[0] != "" &&
        props.content.length > 0
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  return (
    <>
      <Text style={{ alignSelf: "center" }}>Activité à découvrir :</Text>
      <View style={styles.body}>
        <Image style={styles.image} source={{ uri: props.imageurl }} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.titre}>{props.titre}</Text>
            <Text style={styles.distance}>à {props.distance} km</Text>
          </View>
          <View style={styles.separator}></View>
          <Text style={styles.adresse}>{props.adresse}</Text>
          <View style={styles.separator}></View>
          <Text style={styles.telephone}>{props.telephone}</Text>
          <View style={styles.separator}></View>
          <View style={styles.footer}>
            <TouchableOpacity style={styles.btn} onPress={() => openMap()}>
              <View style={styles.inner_btn}>
                <Text style={styles.txt_btn}>Itinéraire</Text>
              </View>
            </TouchableOpacity>
            <View style={styles.separator}></View>
            {check_website() ? (
              <>
                <TouchableOpacity
                  style={styles.btn_web}
                  onPress={() =>
                    props.navigation.navigate("Webview", {
                      website: props.website,
                    })
                  }
                >
                  <Text style={styles.txt_btn_web}>Découvrir</Text>
                </TouchableOpacity>
                <View style={styles.separator}></View>
              </>
            ) : null}
          </View>
          {check_content() ? (
            <>
              <View style={styles.footer}>
                <TouchableOpacity
                  style={styles.btn_web}
                  onPress={() =>
                    props.navigation.navigate("Details", {
                      activityName: props.titre,
                      imageurl: props.imageurl,
                      content: props.content,
                      adresse: props.adresse,
                      telephone: props.telephone,
                      website: props.website,
                    })
                  }
                >
                  <Text style={styles.txt_btn_web}>Details</Text>
                </TouchableOpacity>
              </View>
            </>
          ) : null}
        </View>
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  body: {
    height: 200,
    flexDirection: "row",
    borderRadius: 3,
    padding: 10,
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderColor: "gold",
  },
  image: {
    flex: 1,
    borderRadius: 3,
  },
  content: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    paddingLeft: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titre: {
    width: "70%",
    flexWrap: "wrap",
    fontWeight: "bold",
    fontSize: 15,
  },
  distance: {
    fontSize: 13,
    fontWeight: "bold",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  separator: {
    margin: 5,
  },
  btn: {
    flex: 1,
    backgroundColor: "#eee",
    borderRadius: 3,
  },
  btn_web: {
    flex: 1,
    backgroundColor: "#e70181",
    borderRadius: 3,
  },
  txt_btn: {
    alignSelf: "center",
  },
  txt_btn_web: {
    alignSelf: "center",
    color: "#FFFFFF",
  },
});

export default HighlightedItem;
