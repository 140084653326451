import { Platform } from "react-native";
const BaseUrl = () => {
  let baseUrl = "";
  if (Platform.OS === "ios" || Platform.OS === "android") {
    baseUrl = "https://www.app.proxifun.com";
  }
  return baseUrl;
};

export default BaseUrl;
