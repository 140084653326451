import { NavigationContainer } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import Activity from "./pages/Activity";
import Home from "./pages/Home";
import Details from "./pages/Details";
import Webview from "./pages/Webview";
import Mapview from "./pages/Mapview";
import Admin from "./pages/Admin";
import Add from "./pages/Add";
import Edit from "./pages/Edit";
import PubWebView from "./pages/PubWebView";
import Menu from "./pages/Menu";
import About from "./pages/About";
import Connexion from "./pages/Connexion";
import DashBoard from "./pages/DashBoard";
import Script from "./components/Script";
import TakeActivity from "./pages/TakeActivity";
import Payment from "./pages/Payment";

const Stack = createNativeStackNavigator();

const App = () => {
  return (
    <NavigationContainer>
      <Stack.Navigator>
        <Stack.Screen
          name="Home"
          component={Home}
          options={{
            title: "Proxifun - Accueil",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Activities"
          component={Activity}
          options={{
            title: "Proxifun - Activités",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Details"
          component={Details}
          options={{
            title: "Proxifun - Détail",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Webview"
          component={Webview}
          options={{
            title: "Proxifun - Site Web",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="PubWebView"
          component={PubWebView}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Mapview"
          component={Mapview}
          options={{
            title: "Proxifun - Carte",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Admin"
          component={Admin}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Add"
          component={Add}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Edit"
          component={Edit}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Menu"
          component={Menu}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="About"
          component={About}
          options={{
            title: "Proxifun - A propos",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Connexion"
          component={Connexion}
          options={{
            title: "Proxifun - Espace Pro",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="DashBoard"
          component={DashBoard}
          options={{
            title: "Proxifun - Tableau de bord",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Payment"
          component={Payment}
          options={{
            title: "Proxifun - Paiement",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="Script"
          component={Script}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
        <Stack.Screen
          name="TakeActivity"
          component={TakeActivity}
          options={{
            title: "Proxifun",
            headerShown: false,
          }}
        />
      </Stack.Navigator>
    </NavigationContainer>
  );
};

export default App;
