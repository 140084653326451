import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
} from "react-native";
import React from "react";
import logAnalytics from "../Utils/Analytics";

const Item = (props) => {
  const openMap = async () => {
    const provider = Platform.OS === "ios" ? "apple" : "google";
    const link = `http://maps.${provider}.com/?daddr=${props.titre} ${props.adresse}`;

    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
      logAnalytics("itineraire", props.titre);
    } catch (error) {
      console.log(error);
    }
  };

  const check_website = () => {
    if (props.website.length > 0 && props.website != "null") {
      return true;
    } else {
      return false;
    }
  };

  const check_content = () => {
    if (
      typeof props.content !== "undefined" &&
      props.content.length > 0 &&
      props.content != "null"
    ) {
      if (
        props.content[0] != "V" &&
        props.content[0] != "<" &&
        props.content[0] != "" &&
        props.content.length > 0
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  const renderItem = () => {
    return (
      <View style={styles.body}>
        <Image style={styles.image} source={{ uri: props.imageurl }} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.titre}>{props.titre}</Text>
            <Text style={styles.distance}>à {props.distance} km</Text>
          </View>
          <View style={styles.separator}></View>
          <Text style={styles.adresse}>{props.adresse}</Text>
          <View style={styles.separator}></View>
          <Text style={styles.telephone}>{props.telephone}</Text>
          <View style={styles.separator}></View>
          <View style={styles.footer}>
            <TouchableOpacity style={styles.btn} onPress={() => openMap()}>
              <View style={styles.inner_btn}>
                <Text style={styles.txt_btn}>Itinéraire</Text>
              </View>
            </TouchableOpacity>

            {check_website() ? (
              <>
                <View style={styles.separator}></View>
                <TouchableOpacity
                  style={styles.btn_web}
                  onPress={() => {
                    /*props.navigation.navigate("Webview", {
                  website: props.website,
                  titre: props.titre,
                });*/
                    //console.log(props.website);
                  }}
                >
                  <Text style={styles.txt_btn_web}>Découvrir</Text>
                </TouchableOpacity>
              </>
            ) : null}
          </View>
          {check_content() ? (
            <>
              <View style={styles.separator} />
              <TouchableOpacity
                style={styles.btn_web}
                onPress={() =>
                  props.navigation.navigate("Details", {
                    activityName: props.titre,
                    imageurl: props.imageurl,
                    content: props.content,
                    adresse: props.adresse,
                    telephone: props.telephone,
                    website: props.website,
                  })
                }
              >
                <Text style={styles.txt_btn_web}>Details</Text>
              </TouchableOpacity>
            </>
          ) : null}
        </View>
      </View>
    );
  };
  return renderItem();
};

const styles = StyleSheet.create({
  body: {
    flex: 1,
    flexDirection: "row",
    borderRadius: 3,
    padding: 10,
  },
  image: {
    flex: 1,
    borderRadius: 3,
  },
  content: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    paddingLeft: 10,
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titre: {
    width: "70%",
    flexWrap: "wrap",
    fontWeight: "bold",
    fontSize: 15,
  },
  distance: {
    fontSize: 13,
    fontWeight: "bold",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  separator: {
    margin: 5,
  },
  btn: {
    flex: 1,
    backgroundColor: "#eee",
    borderRadius: 3,
  },
  btn_web: {
    flex: 1,
    backgroundColor: "#e70181",
    borderRadius: 3,
  },
  txt_btn: {
    alignSelf: "center",
  },
  txt_btn_web: {
    alignSelf: "center",
    color: "#FFFFFF",
  },
});

export default Item;
