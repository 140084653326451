import { Platform } from "react-native";
import Location from "../../assets/location_white.svg";

function support() {
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return true;
  } else {
    return false;
  }
}

const LocationSVG = () => {
  if (support()) {
    return <Location width={25} height={25} fill={"white"} />;
  } else {
    return <img src={Location} width={25} height={25} />;
  }
};
export default LocationSVG;
