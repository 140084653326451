import {
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
} from "react-native";
import React, { useState } from "react";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import Axios from "../Utils/Axios";

const Admin = (props) => {
  const [highlighted, setHighlighted] = useState([]);

  const getHighlightedActivities = () => {
    const formData = new FormData();
    formData.append("category", -1);
    formData.append("latitude", 0);
    formData.append("longitude", 0);
    const config = {
      method: "post",
      url: "/php/highlighted",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };

    Axios()
      .request(config)
      .then(function (response) {
        if (response.data.items == null) {
          setHighlighted([]);
        } else {
          setHighlighted(response.data.items);
        }
        setIsLoadingHigh(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const renderPage = () => {
    return (
      <View>
        <View style={styles.XSseparator} />
        <Text style={{ alignSelf: "center", fontWeight: "bold", fontSize: 16 }}>
          Espace Moderateur
        </Text>
        <View style={styles.XSseparator} />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => props.navigation.navigate("Add")}
        >
          <Text style={styles.text_btn}>Ajouter</Text>
        </TouchableOpacity>
        <View style={styles.XSseparator} />
        <TouchableOpacity
          style={styles.btn}
          onPress={() =>
            props.navigation.navigate("Edit", {
              type_edit: 1,
            })
          }
        >
          <Text style={styles.text_btn}>Modifier/Supprimer</Text>
        </TouchableOpacity>
        <View style={styles.XSseparator} />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => props.navigation.navigate("Script")}
        >
          <Text style={styles.text_btn}>Nettoyer urls base de données</Text>
        </TouchableOpacity>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderPage()}</View>
    </SafeAreaView>
  );
};

export default Admin;
