import {
  View,
  TouchableOpacity,
  Text,
  StatusBar,
  SafeAreaView,
} from "react-native";
import React from "react";
import styles from "../styles/Styles";

const Menu = (props) => {
  const renderMenu = () => {
    return (
      <View style={styles.menu}>
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            props.navigation.navigate("Home");
          }}
        >
          <Text style={styles.text_menu}>Accueil</Text>
        </TouchableOpacity>
        <View style={styles.XLseparator} />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            props.navigation.navigate("Connexion");
          }}
        >
          <Text style={styles.text_menu}>Espace Professionnel</Text>
        </TouchableOpacity>
        <View style={styles.XLseparator} />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => {
            props.navigation.navigate("About");
          }}
        >
          <Text style={styles.text_menu}>A propos</Text>
        </TouchableOpacity>
        <View style={styles.XLseparator} />
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#000"} />
      {renderMenu()}
    </SafeAreaView>
  );
};

export default Menu;
