import { Platform } from "react-native";
import Burger from "../../assets/burger.svg";
import BurgerW from "../../assets/burger_white.svg";
import BurgerP from "../../assets/burger_pink.svg";

function support() {
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return true;
  } else {
    return false;
  }
}

const BurgerSVG = (props) => {
  if (support()) {
    if (props.pageId) {
      return (
        <Burger
          width={40}
          height={40}
          fill={props.pageId == 2 ? "#e70181" : "white"}
        />
      );
    } else {
      return <Burger width={25} height={25} fill={"white"} />;
    }
  } else {
    if (props.pageId) {
      if (props.pageId == 2) {
        return <img src={BurgerP} width={40} height={40} />;
      } else {
        return <img src={BurgerW} width={40} height={40} />;
      }
    } else {
      return <img src={BurgerW} width={25} height={25} fill={"white"} />;
    }
  }
};
export default BurgerSVG;
