import React from "react";
import {
  SafeAreaView,
  View,
  StatusBar,
  TouchableOpacity,
  Text,
} from "react-native";
import { WebView } from "react-native-webview";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";

const PubWebView = (props) => {
  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>
        <WebView
          showsVerticalScrollIndicator={false}
          style={{ flex: 1 }}
          source={{ uri: props.route.params.website }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          onMessage={(event) => {}}
          ref={() => {}}
        />
        <TouchableOpacity
          style={styles.btn}
          onPress={() => props.navigation.navigate("Connexion")}
        >
          <Text style={styles.text_btn}>COMMANDER</Text>
        </TouchableOpacity>
        <View style={styles.XSseparator} />
      </View>
    </SafeAreaView>
  );
};

export default PubWebView;
