import { Text, View, StatusBar, LogBox, SafeAreaView } from "react-native";
import React, { useState, useEffect } from "react";
import TabBar from "../components/TabBar";
import scriptHTML from "../html/scriptHTML";
import { WebView } from "react-native-webview";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import CustomHeader from "../components/CustomHeader";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import logAnalytics from "../Utils/Analytics";

LogBox.ignoreLogs([
  "Non-serializable values were found in the navigation state",
]);

const Mapview = (props) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    logAnalytics("page", "Carte");
  }, []);

  const getIconUrl = (catId, item) => {
    let category = -1;
    let categoryName = "";
    if (catId == "-1") {
      category = item.id_category;
    } else {
      category = catId;
    }
    switch (category) {
      case "1":
        categoryName = "Accrobranches";
        return (
          `var catIcon1 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/1.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon1}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "2":
        categoryName = "Balade en bateau";
        return (
          `var catIcon2 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/2.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon2}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "3":
        categoryName = "Bien être";
        return (
          `var catIcon3 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/3.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon3}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "4":
        categoryName = "Bowlings";
        return (
          `var catIcon4 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/4.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon4}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "5":
        categoryName = "Châteaux et jardins";
        return (
          `var catIcon5 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/5.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon5}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "6":
        categoryName = "Cinéma";
        return (
          `var catIcon6 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/6.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon6}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "7":
        categoryName = "Conduite et circuits";
        return (
          `var catIcon7 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/7.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon7}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "8":
        categoryName = "Cours et ateliers divers";
        return (
          `var catIcon8 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/8.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon8}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "9":
        categoryName = "Dans les airs";
        return (
          `var catIcon9 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/9.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon9}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "10":
        categoryName = "Événements";
        return (
          `var catIcon10 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/10.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon10}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "11":
        categoryName = "Jeux et divertissements";
        return (
          `var catIcon11 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/11.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon11}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "12":
        categoryName = "Musées et visites divers";
        return (
          `var catIcon12 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/12.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon12}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "13":
        categoryName = "Paintball et laser";
        return (
          `var catIcon13 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/13.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon13}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "14":
        categoryName = "Parcs aquatiques et attractions";
        return (
          `var catIcon14 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/14.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon14}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "15":
        categoryName = "Patinoires";
        return (
          `var catIcon15 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/15.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon15}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "16":
        categoryName = "Plein air";
        return (
          `var catIcon16 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/16.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon16}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "17":
        categoryName = "Randonnées équestres";
        return (
          `var catIcon17 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/17.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon17}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "18":
        categoryName = "Shopping";
        return (
          `var catIcon18 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/18.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon18}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "19":
        categoryName = "Skatepark";
        return (
          `var catIcon19 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/19.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon19}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "20":
        categoryName = "Ski";
        return (
          `var catIcon20 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/20.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon20}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "21":
        categoryName = "Soccer";
        return (
          `var catIcon21 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/21.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon21}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "22":
        categoryName = "Sortir";
        return (
          `var catIcon22 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/22.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon22}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "23":
        categoryName = "Sur l'eau";
        return (
          `var catIcon23 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/23.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon23}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "24":
        categoryName = "Zoo et aquarium";
        return (
          `var catIcon24 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/24.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon24}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "25":
        categoryName = "Golf";
        return (
          `var catIcon25 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/25.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon25}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "26":
        categoryName = "Non classés";
        return (
          `var catIcon26 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/26.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon26}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "28":
        categoryName = "Escape Game";
        return (
          `var catIcon28 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/27.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon28}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      case "29":
        categoryName = "Escalade";
        return (
          `var catIcon29 = L.icon({
          iconUrl:'https://www.proxifun.com/wp-content/uploads/2022/05/28.png',
          iconSize: [40, 40]});L.marker([` +
          item.latitude +
          `, ` +
          item.longitude +
          `], {icon: catIcon29}).addTo(mymap).bindPopup("` +
          categoryName +
          `<br/><strong>` +
          item.title +
          `</strong><br/>à ` +
          item.distance +
          ` km` +
          `");`
        );
        break;
      default:
        return "";
        break;
    }
  };

  const putMarkers = () => {
    let limit = 500;
    let script = `var myIcon = L.icon({
          iconUrl: 'https://www.proxifun.com/wp-content/uploads/2022/05/0.png',
          iconSize: [50, 50]
      });`;
    script +=
      "mymap.setView([" +
      props.route.params.latitude +
      ", " +
      props.route.params.longitude +
      "], 10);";
    script +=
      "L.marker([" +
      props.route.params.latitude +
      ", " +
      props.route.params.longitude +
      '], {icon: myIcon}).addTo(mymap).bindPopup("Ma position");';
    if (props.route.params.data != null) {
      if (props.route.params.data.length > limit) {
        for (let i = 0; i < limit; i++) {
          script += getIconUrl(
            props.route.params.categoryId,
            props.route.params.data[i]
          );
        }
      } else {
        for (let i = 0; i < props.route.params.data.length; i++) {
          script += getIconUrl(
            props.route.params.categoryId,
            props.route.params.data[i]
          );
        }
      }
    }
    return script;
  };

  const renderMapView = () => {
    return (
      <View style={{ flex: 1 }}>
        <Text style={{ alignSelf: "center", margin: 10, fontSize: 14 }}>
          <Text style={{ fontWeight: "bold" }}>
            {props.route.params.categoryName}
          </Text>{" "}
          près de{" "}
          <Text style={{ fontWeight: "bold" }}>{props.route.params.city}</Text>
        </Text>
        {isLoading ? (
          <ActivityIndicatorElement />
        ) : (
          <WebView
            style={{ flex: 1 }}
            injectedJavaScript={putMarkers()}
            source={{ html: scriptHTML }}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            onLoadEnd={() => setIsLoading(false)}
            onMessage={(event) => {}}
            ref={() => {}}
          />
        )}
        <TabBar
          pageId={2}
          navigation={props.navigation}
          categoryName={props.route.params.categoryName}
          categoryId={props.route.params.categoryId}
          latitude={props.route.params.latitude}
          longitude={props.route.params.longitude}
          city={props.route.params.city}
        />
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderMapView()}</View>
    </SafeAreaView>
  );
};

export default Mapview;
