import { View, StyleSheet, ScrollView } from "react-native";
import React from "react";
import CatItem from "./CatItem";

const Category = (props) => {
  return (
    <View style={styles.container}>
      <ScrollView showsVerticalScrollIndicator={false}>
        <View style={{ flexDirection: "row", flex: 1 }}>
          <CatItem
            nom="Toutes les catégories"
            catId="-1"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Accrobranches"
            catId="1"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Balade en bateau"
            catId="2"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Bien être"
            catId="3"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Bowlings"
            catId="4"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Châteaux et jardins"
            catId="5"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Cinéma"
            catId="6"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Conduite et circuits"
            catId="7"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Cours et ateliers divers"
            catId="8"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Dans les airs"
            catId="9"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Événements"
            catId="10"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Escape Game"
            catId="28"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Escalade"
            catId="29"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Golf"
            catId="25"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Jeux et divertissements"
            catId="11"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Musées et visites divers"
            catId="12"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Paintball et laser"
            catId="13"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Parcs aquatiques et attractions"
            catId="14"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Patinoires"
            catId="15"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Plein air"
            catId="16"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Randonnées équestres"
            catId="17"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Shopping"
            catId="18"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Skatepark"
            catId="19"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Ski"
            catId="20"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Soccer"
            catId="21"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Sortir"
            catId="22"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Sur l'eau"
            catId="23"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.separator} />
        <View style={styles.cat}>
          <CatItem
            nom="Zoo et aquarium"
            catId="24"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
          <View style={styles.separator} />
          <CatItem
            nom="Non classés"
            catId="26"
            latitude={props.latitude}
            longitude={props.longitude}
            navigation={props.navigation}
            city={props.city}
          />
        </View>
        <View style={styles.footer} />
      </ScrollView>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  cat: {
    flexDirection: "row",
    flex: 1,
  },
  separator: {
    margin: 1,
  },
  footer: {
    margin: 30,
  },
});

export default Category;
