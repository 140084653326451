import { View } from "react-native";

const FlatListItemSeparator = () => {
  return (
    <View
      style={{
        height: 5,
        backgroundColor: "#eee",
      }}
    />
  );
};

export default FlatListItemSeparator;
