import React, { useEffect } from "react";
import { SafeAreaView, View, Linking, Platform } from "react-native";
import { WebView } from "react-native-webview";
import SubContainerStyle from "../styles/SubContainerStyle";
import Styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import logAnalytics from "../Utils/Analytics";

const Payment = (props) => {
  useEffect(() => {
    logAnalytics("page", "Page de paiement");
  }, []);

  function getLink() {
    switch (props.route.params.product) {
      case 0:
        return (
          "https://www.app.proxifun.com/stripe-sample-code/create-checkout-session_mois?id=" +
          props.route.params.id
        );
        break;
      case 1:
        return (
          "https://www.app.proxifun.com/stripe-sample-code/create-checkout-session_trim?id=" +
          props.route.params.id
        );
        break;
      case 2:
        return (
          "https://www.app.proxifun.com/stripe-sample-code/create-checkout-session_sem?id=" +
          props.route.params.id
        );
        break;
      case 3:
        return (
          "https://www.app.proxifun.com/stripe-sample-code/create-checkout-session_an?id=" +
          props.route.params.id
        );
        break;
    }
  }

  const openWebsite = async () => {
    try {
      const supported = await Linking.canOpenURL(getLink());
      if (supported) Linking.openURL(getLink());
      props.navigation.goBack();
    } catch (error) {
      console.log(error);
    }
  };

  function check_platform() {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      return true;
    } else {
      return false;
    }
  }

  const renderPayment = () => {
    if (check_platform()) {
      return (
        <WebView
          style={{ flex: 1 }}
          source={{
            uri: getLink(),
          }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          onMessage={(event) => {}}
          ref={() => {}}
        />
      );
    } else {
      openWebsite();
    }
  };

  return (
    <SafeAreaView style={Styles.main_container}>
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderPayment()}</View>
    </SafeAreaView>
  );
};

export default Payment;
