import {
  Text,
  View,
  ScrollView,
  SafeAreaView,
  StatusBar,
  StyleSheet,
  Image,
  TouchableOpacity,
  Linking,
  Platform,
} from "react-native";
import React, { useState, useEffect } from "react";
import ErreurPage from "./ErreurPage";
import TabBar from "../components/TabBar";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import HighlightedItem from "../components/HighlightedItem";
import PubHighlighted from "../components/PubHighlighted";
import SubContainerStyle from "../styles/SubContainerStyle";
import mainStyles from "../styles/Styles";
import Axios from "../Utils/Axios";
import logAnalytics from "../Utils/Analytics";
import WebView from "react-native-webview";
import CustomHaderStyle from "../styles/CustomeHeaderStyle";
import BackSVG from "../Utils/SvgAssets/BackSVG";
import PubSlot from "../components/PubSlot";

const FormData = global.FormData;
let count = 0;
let indicePub = 0;
let onePub = 0;

export default function Activity(props) {
  const [data, setData] = useState([]);
  const [highlighted, setHighlighted] = useState([]);
  const [isLoadingHigh, setIsLoadingHigh] = useState(true);
  const [isLoadingAct, setIsLoadingAct] = useState(true);
  const [distance, setDistance] = useState(200);
  const [seeWebSite, setSeeWebSite] = useState(false);
  const [url, setUrl] = useState(null);
  const [type, setType] = useState(2);

  const formData = new FormData();
  formData.append("category", props.route.params.categoryId);
  formData.append("latitude", props.route.params.latitude);
  formData.append("longitude", props.route.params.longitude);
  formData.append("distanceMax", distance);

  const getActivities = () => {
    const config = {
      method: "post",
      url: "/php/act",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };

    Axios()
      .request(config)
      .then(function (response) {
        if (response.data.items == null) {
          setData([]);
        } else {
          setData(response.data.items);
        }

        setIsLoadingAct(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const getHighlightedActivities = () => {
    const config = {
      method: "post",
      url: "/php/highlighted",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };

    Axios()
      .request(config)
      .then(function (response) {
        if (response.data.items == null) {
          setHighlighted([]);
        } else {
          checkDateHighlighted(response.data.items);
          setHighlighted(response.data.items);
        }
        setIsLoadingHigh(false);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  function checkDateHighlighted(tabHighItem) {
    tabHighItem.map((highItem) => {
      if (highItem.end_highlighted != null) {
        let endYear = highItem.end_highlighted.substring(0, 4);
        let endMonth = highItem.end_highlighted.substring(5, 7);
        let endDay = highItem.end_highlighted.substring(8, 10);
        let endDate = new Date(endYear, endMonth, endDay);
        let currentDate = new Date();
        let currentYear = currentDate.getFullYear();
        let currentMonth = currentDate.getMonth();
        let currentDay = currentDate.getDay();
        let actualDate = new Date(currentYear, currentMonth, currentDay);
        if (endDate < actualDate) {
          updateHighlighted(highItem.id);
        }
      }
    });
  }

  const updateHighlighted = (id) => {
    const formData = new FormData();
    formData.append("id", id);

    const config = {
      method: "post",
      url: "/php/updateHighlighted",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    Axios()
      .request(config)
      .then(function (response) {
        console.log(response.data);
      });
  };

  useEffect(() => {
    getActivities();
    getHighlightedActivities();
  }, []);

  useEffect(() => {
    if (url != null) {
      setSeeWebSite(true);
      setType(2);
      //console.log(type);
    } else {
      setSeeWebSite(false);
      setType(1);
      //console.log(type);
    }
  }, [url]);

  useEffect(() => {
    logAnalytics("page", "Liste activités");
    logAnalytics(
      "categorie_ville",
      props.route.params.categoryName + " - " + props.route.params.city
    );
    logAnalytics("categorie", props.route.params.categoryName);
    logAnalytics("ville", props.route.params.city);
  }, []);

  useEffect(() => {
    count = 0;
    indicePub = 0;
    onePub = 0;
  });

  const renderHighligthedItem = (indice) => {
    if (
      typeof highlighted[indice] !== "undefined" &&
      highlighted.length > 0 &&
      indice < highlighted.length
    ) {
      return (
        <HighlightedItem
          key={highlighted[indice].id}
          titre={highlighted[indice].title}
          distance={highlighted[indice].distance}
          adresse={highlighted[indice].address}
          telephone={highlighted[indice].phoneNumber}
          content={highlighted[indice].content}
          imageurl={highlighted[indice].picture}
          website={highlighted[indice].website}
          navigation={props.navigation}
        />
      );
    } else {
      if (onePub == 0) {
        onePub++;
        return <PubHighlighted navigation={props.navigation} />;
      } else {
        return null;
      }
    }
  };

  const renderActivity = () => {
    if (isLoadingHigh || isLoadingAct) {
      return <ActivityIndicatorElement />;
    } else if (data) {
      return (
        <View style={mainStyles.main_container}>
          <ScrollView
            style={mainStyles.main_container}
            showsVerticalScrollIndicator={false}
          >
            <Text style={{ alignSelf: "center", margin: 10, fontSize: 14 }}>
              <Text style={mainStyles.txt_bold}>
                {props.route.params.categoryName} ({data.length})
              </Text>{" "}
              près de{" "}
              <Text style={mainStyles.txt_bold}>{props.route.params.city}</Text>
            </Text>
            {data != null
              ? data.map((item) => {
                  //console.log(count);
                  count++;
                  if ((count - 1) % 6 == 0) {
                    indicePub++;
                    //console.log("pub ici");
                    return (
                      <>
                        {renderHighligthedItem(indicePub - 1)}
                        {renderItem(item)}
                      </>
                    );
                  } else {
                    return renderItem(item);
                  }
                })
              : null}
            <View style={{ margin: 40 }} />
          </ScrollView>
          <TabBar
            data={data}
            pageId={1}
            navigation={props.navigation}
            categoryName={props.route.params.categoryName}
            categoryId={props.route.params.categoryId}
            latitude={props.route.params.latitude}
            longitude={props.route.params.longitude}
            city={props.route.params.city}
          />
        </View>
      );
    } else {
      return (
        <ErreurPage
          catName={props.route.params.categoryName}
          cityName={props.route.params.city}
        />
      );
    }
  };

  function check_website_url(url) {
    let isSecure = false;
    if (url != null) {
      if (Platform.OS !== "android" && Platform.OS !== "ios") {
        //console.log(url);
        url[4] == "s" ? (isSecure = true) : null;
      } else {
        isSecure = true;
      }
    }

    return isSecure;
  }

  const openWebsite = async (link) => {
    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log(error);
    }
  };

  const openWebsiteInWebview = () => {
    return (
      <>
        <Text style={{ fontWeight: "bold", margin: 5 }}>
          Site web de l'activité :
        </Text>
        <WebView
          style={{ flex: 1, margin: 5 }}
          source={{ uri: url }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          onMessage={(event) => {}}
          ref={() => {}}
        />
        <View style={{ margin: 10 }} />
      </>
    );
  };

  const openWebsiteInNewWindow = () => {
    return (
      <View style={{ flex: 1, marginTop: 30 }}>
        <Text style={{ textAlign: "center", fontSize: 17 }}>
          Le site web {url} n'est pas sécurisé : vous pouvez cependant l'ouvrir
          dans un nouvel onglet
        </Text>
        <View style={{ margin: 10 }}></View>
        <TouchableOpacity
          style={{
            backgroundColor: "#e70181",
            width: "50%",
            alignSelf: "center",
            padding: 10,
            borderRadius: 3,
          }}
          onPress={() => openWebsite(url)}
        >
          <Text style={{ textAlign: "center", color: "#FFFFFF" }}>
            Ouvrir dans un nouvel onglet
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const verifyAndRender = () => {
    return check_website_url(url)
      ? openWebsiteInWebview()
      : openWebsiteInNewWindow();
  };

  const renderWebsite = () => {
    return (
      <>
        <PubSlot />
        {verifyAndRender()}
      </>
    );
  };

  const openMap = async (item) => {
    const provider = Platform.OS === "ios" ? "apple" : "google";
    const link = `http://maps.${provider}.com/?daddr=${item.title} ${item.address}`;

    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
      logAnalytics("itineraire", item.title);
    } catch (error) {
      console.log(error);
    }
  };

  const check_website = (website) => {
    if (website.length > 0 && website != "null") {
      return true;
    } else {
      return false;
    }
  };

  const check_content = (content) => {
    if (
      typeof content !== "undefined" &&
      content.length > 0 &&
      content != "null"
    ) {
      if (
        content[0] != "V" &&
        content[0] != "<" &&
        content[0] != "" &&
        content.length > 0
      ) {
        return true;
      }
    } else {
      return false;
    }
  };

  function renderItem(item) {
    return (
      <View style={styles.body}>
        <Image style={styles.image} source={{ uri: item.picture }} />
        <View style={styles.content}>
          <View style={styles.header}>
            <Text style={styles.titre}>{item.title}</Text>
            <Text style={styles.distance}>à {item.distance} km</Text>
          </View>
          <View style={styles.separator}></View>
          <Text style={styles.adresse}>{item.address}</Text>
          <View style={styles.separator}></View>
          {item.phoneNumber != "null" ? (
            <>
              <Text style={styles.telephone}>{item.phoneNumber}</Text>
              <View style={styles.separator}></View>
            </>
          ) : null}

          <View style={styles.footer}>
            <TouchableOpacity
              style={styles.btn_iti}
              onPress={() => openMap(item)}
            >
              <Text style={styles.btn}>Itinéraire</Text>
            </TouchableOpacity>

            {check_website(item.website) ? (
              <>
                <View style={styles.separator}></View>
                <TouchableOpacity
                  style={styles.btn_web}
                  onPress={() => {
                    //console.log(item.website);
                    setUrl(item.website);
                  }}
                >
                  <Text style={styles.txt_btn_web}>Découvrir</Text>
                </TouchableOpacity>
              </>
            ) : null}
          </View>
          {check_content(item.content) ? (
            <View style={styles.footer}>
              <TouchableOpacity
                style={styles.btn_web}
                onPress={() =>
                  props.navigation.navigate("Details", {
                    activityName: item.title,
                    imageurl: item.picture,
                    content: item.content,
                    adresse: item.address,
                    telephone: item.phoneNumber,
                    website: item.website,
                  })
                }
              >
                <Text style={styles.txt_btn_web}>Détail</Text>
              </TouchableOpacity>
            </View>
          ) : null}
        </View>
      </View>
    );
  }

  const renderCustomHeader = () => {
    if (type == 2) {
      return (
        <>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              setUrl(null);
            }}
          >
            <BackSVG />
          </TouchableOpacity>
          <View style={styles.logo_container}>
            <Image
              style={{ width: 150, height: 40, alignSelf: "center" }}
              source={require("../assets/logo2022-2.png")}
            />
          </View>
          <View style={styles.btn}></View>
        </>
      );
    } else if (type == 1) {
      return (
        <>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <BackSVG />
          </TouchableOpacity>
          <View style={styles.logo_container}>
            <Image
              style={{ width: 150, height: 40, alignSelf: "center" }}
              source={require("../assets/logo2022-2.png")}
            />
          </View>
          <View style={styles.btn}></View>
        </>
      );
    }
  };

  return (
    <SafeAreaView style={mainStyles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <View style={CustomHaderStyle()}>{renderCustomHeader()}</View>
      <View style={SubContainerStyle()}>
        {seeWebSite ? renderWebsite() : renderActivity()}
      </View>
    </SafeAreaView>
  );
}

const styles = StyleSheet.create({
  body: {
    height: 200,
    flexDirection: "row",
    borderRadius: 3,
    padding: 10,
  },
  image: {
    flex: 2,
    borderRadius: 3,
  },
  content: {
    flexDirection: "column",
    justifyContent: "space-between",
    flex: 3,
    paddingLeft: 10,
    maxHeight: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  titre: {
    flexWrap: "wrap",
    fontWeight: "bold",
    fontSize: 15,
    maxWidth: "70%",
  },
  distance: {
    fontSize: 13,
    fontWeight: "bold",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  separator: {
    margin: 5,
  },
  btn_iti: {
    flex: 1,
    backgroundColor: "#eee",
    borderRadius: 3,
  },
  btn_web: {
    flex: 1,
    backgroundColor: "#e70181",
    borderRadius: 3,
  },
  txt_btn: {
    alignSelf: "center",
  },
  txt_btn_web: {
    alignSelf: "center",
    color: "#FFFFFF",
  },
  logo_container: {
    flex: 4,
  },
  btn: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});
