import { Text, View, ScrollView, SafeAreaView, StatusBar } from "react-native";
import React, { useState, useEffect } from "react";
import Axios from "../Utils/Axios";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import logAnalytics from "../Utils/Analytics";

const About = (props) => {
  const [data, setData] = useState(null);

  const getInfos = () => {
    const config = {
      method: "post",
      url: "/php/about",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
    };

    Axios()
      .request(config)
      .then(function (response) {
        setData(response.data.nb);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  useEffect(() => {
    getInfos();
    logAnalytics("page", "A propos");
  }, []);

  const getName = (id) => {
    let categoryName = "";
    switch (id) {
      case "1":
        categoryName = "Accrobranches";
        break;
      case "2":
        categoryName = "Balade en bateau";
        break;
      case "3":
        categoryName = "Bien être";
        break;
      case "4":
        categoryName = "Bowlings";
        break;
      case "5":
        categoryName = "Châteaux et jardins";
        break;
      case "6":
        categoryName = "Cinéma";
        break;
      case "7":
        categoryName = "Conduite et circuits";
        break;
      case "8":
        categoryName = "Cours et ateliers divers";
        break;
      case "9":
        categoryName = "Dans les airs";
        break;
      case "10":
        categoryName = "Événements";
        break;
      case "11":
        categoryName = "Jeux et divertissements";
        break;
      case "12":
        categoryName = "Musées et visites divers";
        break;
      case "13":
        categoryName = "Paintball et laser";
        break;
      case "14":
        categoryName = "Parcs aquatiques et attractions";
        break;
      case "15":
        categoryName = "Patinoires";
        break;
      case "16":
        categoryName = "Plein air";
        break;
      case "17":
        categoryName = "Randonnées équestres";
        break;
      case "18":
        categoryName = "Shopping";
        break;
      case "19":
        categoryName = "Skatepark";
        break;
      case "20":
        categoryName = "Ski";
        break;
      case "21":
        categoryName = "Soccer";
        break;
      case "22":
        categoryName = "Sortir";
        break;
      case "23":
        categoryName = "Sur l'eau";
        break;
      case "24":
        categoryName = "Zoo et aquarium";
        break;
      case "25":
        categoryName = "Golf";
        break;
      case "26":
        categoryName = "Non classés";
        break;
      case "28":
        categoryName = "Escape Game";
        break;
      case "29":
        categoryName = "Escalade";
        break;
      default:
        return "";
        break;
    }
    return categoryName;
  };

  const renderInfo = () => {
    return (
      <View style={styles.margin}>
        <Text style={{ fontSize: 30, alignSelf: "center" }}>A propos</Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 22, textAlign: "center" }}>
          Trouvez facilement les activités de loisirs proche de vous !
        </Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 16, textAlign: "center" }}>FUN FUN FUN !</Text>
        <Text style={{ fontSize: 16, textAlign: "center" }}>
          Si c’est fun, c’est sur Proxifun !
        </Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 16, textAlign: "center" }}>
          Profitez de la vie ! Amusez-vous ! Sortez entre amis. Sortez en
          famille, faites découvrir des choses à vos enfants. Offrez-vous un
          moment sympa, une journée inoubliable ! VIVEZ !
        </Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 16, textAlign: "center" }}>
          Chez Proxifun, on a juste envie de contribuer à ce que chacun d’entre
          vous profite de la vie.
        </Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 16, textAlign: "center" }}>
          On a sélectionné près de 20 000 activités, et plus on en cherche et
          plus on en trouve, donc si c’est fun, ce sera sur Proxifun !
        </Text>
        <View style={styles.separator} />
        <Text style={{ fontSize: 16, textAlign: "center" }}>
          Près de 20 000 activités dont :
        </Text>
        <View style={styles.separator} />

        {data != null
          ? data.map((item) => (
              <View>
                <Text style={{ textAlign: "center" }}>
                  {getName(item.id_category)} :{" "}
                  <Text style={styles.text_nb}>{item.nb_activities}</Text>
                </Text>
              </View>
            ))
          : null}
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <ScrollView
        style={SubContainerStyle()}
        showsVerticalScrollIndicator={false}
      >
        {renderInfo()}
      </ScrollView>
    </SafeAreaView>
  );
};

export default About;
