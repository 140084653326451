import { StyleSheet } from "react-native";

const styles = StyleSheet.create({
  main_container: {
    flex: 1,
    backgroundColor: "#FFFFFF",
  },
  sidebar_container: {
    position: "absolute",
    left: 10,
  },
  text: {
    alignSelf: "center",
  },
  text_btn: {
    color: "#FFFFFF",
    backgroundColor: "#e70181",
    padding: 10,
    borderRadius: 5,
    alignSelf: "center",
  },
  submit_btn: {
    padding: 10,
    alignSelf: "center",
  },

  XSseparator: {
    margin: 5,
  },
  separator: {
    margin: 10,
  },
  XLseparator: {
    margin: 20,
  },
  txt_bold: {
    alignSelf: "center",
    padding: 10,
    fontWeight: "bold",
  },
  text_nb: {
    alignSelf: "center",
    fontWeight: "bold",
  },
  add: {
    padding: 25,
    backgroundColor: "#25C713",
    alignSelf: "center",
    color: "#FFFFFF",
    borderRadius: 5,
    fontSize: 18,
  },
  picker: {
    width: "90%",
    alignSelf: "center",
    backgroundColor: "#C8C8C8",
    borderRadius: 5,
  },
  radio_btn: {
    padding: 5,
  },
  title: {
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 16,
  },
  logo_container: {
    flex: 4,
  },
  input: {
    margin: 10,
    backgroundColor: "#C8C8C8",
    borderRadius: 5,
    padding: 5,
  },
  btn: {
    alignSelf: "center",
    borderRadius: 5,
  },
  supp_txt: {
    color: "#FFFFFF",
    backgroundColor: "red",
    padding: 10,
    borderRadius: 5,
  },
  icon: {
    height: 30,
    width: 30,
    tintColor: "#FFFFFF",
    alignSelf: "center",
  },
  margin: {
    flex: 1,
    margin: 10,
  },
  text_menu: {
    alignSelf: "center",
    color: "#FFFFFF",
    fontSize: 20,
  },
  menu: {
    flex: 1,
    backgroundColor: "#000",
    justifyContent: "center",
  },
  footer: {
    margin: 30,
  },
});

export default styles;
