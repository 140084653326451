import {
  Text,
  View,
  TextInput,
  TouchableOpacity,
  Alert,
  ScrollView,
  Platform,
  Image,
  SafeAreaView,
  StatusBar,
} from "react-native";
import React, { useState } from "react";
import { Picker } from "@react-native-picker/picker";
import RadioForm from "react-native-simple-radio-button";
import * as Location from "expo-location";
import AwesomeAlert from "react-native-awesome-alerts";
import * as ImagePicker from "expo-image-picker";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import Axios from "../Utils/Axios";
import styles from "../styles/Styles";
import SubContainerStyle from "../styles/SubContainerStyle";
import CustomHeader from "../components/CustomHeader";

const FormData = global.FormData;

var radio_props = [
  { label: "Non", value: 0 },
  { label: "Oui", value: 1 },
];

const Add = (props) => {
  /**
   * Attributs d'une activité
   */
  const [category, setCategory] = useState(1);
  const [nom, setNom] = useState(null);
  const [adress, setAdress] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [telephone, setTelephone] = useState("");
  const [mail, setMail] = useState("");
  const [content, setContent] = useState("");
  const [website, setWebsite] = useState("");
  const [highlighted, setHighlighted] = useState(0);
  const [image, setImage] = useState(null);
  const [id, setId] = useState(0);
  /**
   * Attributs de la page
   */
  const [isLoading, setIsLoading] = useState(false);
  const [genere, setGenere] = useState(false);
  const [alert, setAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState(null);
  const [titleAlert, setTitleAlert] = useState(null);

  const hideAlert = () => {
    setAlert(false);
  };

  const showMsg = (title, message) => {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setAlert(true);
    }
  };

  const removeSpacesFromString = (nom) => {
    return nom.split(" ").join("");
  };

  const verify = () => {
    if (
      category != null &&
      nom != null &&
      adress != null &&
      latitude != null &&
      longitude != null &&
      highlighted != null
    ) {
      return true;
    } else {
      return false;
    }
  };

  const getCoords = async () => {
    if (adress != null) {
      try {
        let coords = await Location.geocodeAsync(adress);
        let lat = coords[0].latitude.toFixed(5);
        let long = coords[0].longitude.toFixed(5);
        setLatitude(lat);
        setLongitude(long);
        setGenere(true);
      } catch (error) {
        setGenere(false);
        showMsg(
          "Erreur lors de la génération des coordonnées GPS",
          "Veuillez les saisir manuellement ou entrer un adresse valide"
        );
      }
    } else {
      setGenere(false);
      showMsg(
        "Erreur lors de la génération des coordonnées GPS",
        "Vous devez spécifier l'adresse pour générer les coordonnées GPS"
      );
    }
  };

  const submit = () => {
    const formData = new FormData();
    formData.append("category", category);
    //let nameWithoutSpace = removeSpacesFromString(nom);
    //console.log(nameWithoutSpace + "a");
    formData.append("name", nom);
    formData.append("adress", adress);
    formData.append("latitude", latitude);
    formData.append("longitude", longitude);
    formData.append("phone", telephone);
    formData.append("mail", mail);
    formData.append("content", content);
    formData.append("website", website);
    formData.append("highlighted", highlighted);

    if (verify()) {
      const config = {
        method: "post",
        url: "/php/add",
        responseType: "json",
        headers: { "Content-Type": "multipart/form-data" },
        transformRequest: (data, headers) => {
          return formData;
        },
        data: formData,
      };
      setIsLoading(true);
      Axios()
        .request(config)
        .then(function (response) {
          //console.log(response.data);
          if (response.data > 0) {
            if (image) {
              image.id = response.data;
              image.title = nom;
              //console.log(image);
              uploadDocument(image);
            }
            setIsLoading(false);
            setGenere(false);
            showMsg(
              "Insertion réussie",
              "L'activité a été insérée avec succès"
            );
          } else {
            setIsLoading(false);
            setGenere(false);
            showMsg(
              "Erreur lors de l'insertion",
              "Une erreur s'est produite lors de l'insertion"
            );
          }
        })
        .catch(function (error) {
          setIsLoading(false);
          setGenere(false);
          console.log(error);
        });
    } else {
      setIsLoading(false);
      setGenere(false);
      showMsg(
        "Erreur lors de l'insertion",
        "Vous devez remplir tous les champs"
      );
    }
  };

  const uploadDocument = (imagedata) => {
    //console.log(imagedata);
    let data = {
      method: "POST",
      body: JSON.stringify({ image: imagedata }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    let targetUrl = "";
    if (Platform.OS === "android" || Platform.OS === "ios") {
      targetUrl = "https://www.app.proxifun.com/php/upload";
    } else {
      targetUrl = "/php/upload";
    }
    fetch(targetUrl, data)
      .then((response) => response.json())
      .then((json) => {
        //console.log(json);
      });
  };

  const pickImage = async () => {
    let options = {
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      //allowsEditing: true,
      //aspect: [4, 3],
      //quality: 1,
      base64: true,
    };
    if (nom != "") {
      // No permissions request is necessary for launching the image library
      let result = await ImagePicker.launchImageLibraryAsync(options);

      if (!result.cancelled) {
        result.title = nom;
        result.id = id;
        //console.log(result.base64);
        setImage(result);
        uploadDocument(result);
      }
    } else {
      showMsg(
        "Erreur lors du chargement de la photo",
        "Vous devez d'abord entrer le nom de l'activité"
      );
    }
  };

  const renderAdd = () => {
    if (isLoading) {
      return <ActivityIndicatorElement />;
    } else {
      return (
        <View style={styles.margin}>
          <Text style={styles.title}>Ajouter</Text>
          <Text>Catégorie :</Text>
          <View style={styles.separator} />
          <View style={styles.picker}>
            <Picker
              selectedValue={category}
              style={styles.picker}
              mode={"dialog"}
              onValueChange={(itemValue) => setCategory(itemValue)}
            >
              <Picker.Item label="Accrobranches" value="1" />
              <Picker.Item label="Balade en bateau" value="2" />
              <Picker.Item label="Bien être" value="3" />
              <Picker.Item label="Bowlings" value="4" />
              <Picker.Item label="Châteaux et jardins" value="5" />
              <Picker.Item label="Cinéma" value="6" />
              <Picker.Item label="Conduite et circuits" value="7" />
              <Picker.Item label="Cours et ateliers divers" value="8" />
              <Picker.Item label="Dans les airs" value="9" />
              <Picker.Item label="Evènements" value="10" />
              <Picker.Item label="Jeux et divertissements" value="11" />
              <Picker.Item label="Musées et visites divers" value="12" />
              <Picker.Item label="Paintball et laser" value="13" />
              <Picker.Item label="Parcs aquatiques et attractions" value="14" />
              <Picker.Item label="Patinoires" value="15" />
              <Picker.Item label="Plein air" value="16" />
              <Picker.Item label="Randonnées équestres" value="17" />
              <Picker.Item label="Shopping" value="18" />
              <Picker.Item label="Skatepark" value="19" />
              <Picker.Item label="Ski" value="20" />
              <Picker.Item label="Football" value="21" />
              <Picker.Item label="Sortir" value="22" />
              <Picker.Item label="Sur l'eau" value="23" />
              <Picker.Item label="Zoo et aquarium" value="24" />
              <Picker.Item label="Golf" value="25" />
              <Picker.Item label="Non classés" value="26" />
              <Picker.Item label="Escape Game" value="28" />
              <Picker.Item label="Escalade" value="29" />
            </Picker>
          </View>
          <View style={styles.separator} />
          <Text>Nom :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setNom}
            placeholder=" Nom de l'activité"
          />
          <Text>Téléphone (optionnel) :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setTelephone}
            placeholder=" 06 19 85 32 68"
          />
          <Text>Mail (optionnel) :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setMail}
            placeholder=" exemple@gmail.com"
          />
          <Text>Adresse :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setAdress}
            placeholder=" Pl. Charles de Gaulle, 75008 Paris"
          />
          <TouchableOpacity
            style={styles.submit_btn}
            onPress={() => getCoords()}
          >
            <Text style={styles.text_btn}>Générer les coordonnées GPS</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.submit_btn}
            onPress={() => setGenere(false)}
          >
            <Text style={styles.text_btn}>
              Saisir les coordonnées manuellement
            </Text>
          </TouchableOpacity>
          {genere ? (
            <View>
              <View style={{ margin: 10 }} />
              <Text>Latitude : {latitude}</Text>
              <View style={{ margin: 10 }} />
              <Text>Longitude : {longitude}</Text>
              <View style={{ margin: 10 }} />
            </View>
          ) : (
            <>
              <Text>Latitude :</Text>
              <TextInput style={styles.input} onChangeText={setLatitude} />
              <Text>Longitude :</Text>
              <TextInput style={styles.input} onChangeText={setLongitude} />
            </>
          )}
          <Text>Description (optionnel) :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setContent}
            placeholder=" Location de vélo électrique"
          />
          <Text>Site web (optionnel) :</Text>
          <TextInput
            style={styles.input}
            onChangeText={setWebsite}
            placeholder=" https://www.izibikes.com"
          />
          <Text>Recommandé :</Text>
          <RadioForm
            style={styles.radio_btn}
            radio_props={radio_props}
            initial={0}
            labelHorizontal={false}
            formHorizontal={true}
            onPress={(value) => {
              setHighlighted(value);
            }}
          />
          <View style={styles.separator} />
          <TouchableOpacity onPress={pickImage} style={styles.image_btn}>
            <Text style={styles.text_btn}>Charger une image</Text>
          </TouchableOpacity>
          <View style={styles.separator} />
          {image && (
            <Image
              source={{ uri: image.uri }}
              style={{ width: 300, height: 200, alignSelf: "center" }}
            />
          )}
          <TouchableOpacity style={styles.submit_btn} onPress={() => submit()}>
            <Text style={styles.add}>Ajouter</Text>
          </TouchableOpacity>
          <AwesomeAlert
            show={alert}
            showProgress={false}
            title={titleAlert}
            message={msgAlert}
            closeOnTouchOutside={true}
            showConfirmButton={true}
            confirmText="Ok"
            confirmButtonColor="#e70181"
            onConfirmPressed={() => {
              hideAlert();
            }}
          />
          <View style={{ margin: 100 }} />
        </View>
      );
    }
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <ScrollView
        style={styles.main_container}
        showsVerticalScrollIndicator={false}
      >
        <View style={SubContainerStyle()}>{renderAdd()}</View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default Add;
