import React, { useState, useEffect } from "react";
import {
  SafeAreaView,
  View,
  TouchableOpacity,
  Platform,
  Text,
  Linking,
  StatusBar,
} from "react-native";
import { WebView } from "react-native-webview";
import PubSlot from "../components/PubSlot";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import styles from "../styles/Styles";
import SubContainerStyle from "../styles/SubContainerStyle";
import CustomHeader from "../components/CustomHeader";
import logAnalytics from "../Utils/Analytics";

const Webview = (props) => {
  const [visible, setVisible] = useState(
    check_platform()
      ? check_website_url(props.route.params.website)
        ? true
        : false
      : false
  );

  useEffect(() => {
    logAnalytics("page", "Site web");
    logAnalytics("site_web", props.route.params.titre);
  }, []);

  function check_website_url(url) {
    let isSecure = false;
    if (Platform.OS !== "android" && Platform.OS !== "ios") {
      //console.log(url);
      url[4] == "s" ? (isSecure = true) : null;
    } else {
      isSecure = true;
    }
    return isSecure;
  }

  const openWebsite = async (link) => {
    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log(error);
    }
  };

  const openWebsiteInWebview = () => {
    return (
      <>
        <Text style={{ fontWeight: "bold", margin: 5 }}>
          Site web de l'activité :
        </Text>
        <WebView
          style={{ flex: 1, margin: 5 }}
          source={{ uri: props.route.params.website }}
          javaScriptEnabled={true}
          domStorageEnabled={true}
          onLoad={() => setVisible(false)}
          onMessage={(event) => {}}
          ref={() => {}}
        />
        <View style={{ margin: 10 }} />
      </>
    );
  };

  const openWebsiteInNewWindow = () => {
    return (
      <View style={{ flex: 1, marginTop: 30 }}>
        <Text style={{ textAlign: "center", fontSize: 17 }}>
          Le site web {props.route.params.website} n'est pas sécurisé : vous
          pouvez cependant l'ouvrir dans un nouvel onglet
        </Text>
        <View style={{ margin: 10 }}></View>
        <TouchableOpacity
          style={{
            backgroundColor: "#e70181",
            width: "50%",
            alignSelf: "center",
            padding: 10,
            borderRadius: 3,
          }}
          onPress={() => openWebsite(props.route.params.website)}
        >
          <Text style={{ textAlign: "center", color: "#FFFFFF" }}>
            Ouvrir dans un nouvel onglet
          </Text>
        </TouchableOpacity>
      </View>
    );
  };

  const verifyAndRender = () => {
    return check_website_url(props.route.params.website)
      ? openWebsiteInWebview()
      : openWebsiteInNewWindow();
  };

  function check_platform() {
    if (Platform.OS === "ios" || Platform.OS === "android") {
      return true;
    } else {
      return false;
    }
  }

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>
        <PubSlot />
        {verifyAndRender()}
        {visible ? <ActivityIndicatorElement /> : null}
      </View>
    </SafeAreaView>
  );
};

export default Webview;
