import React, { useState } from "react";
import {
  View,
  StyleSheet,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import CustomHeader from "./CustomHeader";

const axios = require("axios").default;
const changesUrl = new Array();
const changesId = new Array();
let countUrlToChange = 0;
let countUrlError = 0;
let countUrlHttps = 0;
const limit = 19965;
const start = 0;

const Script = (props) => {
  const [goodUrl, setGoodUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  let baseUrl = "";
  if (Platform.OS === "ios" || Platform.OS === "android") {
    baseUrl = "https://www.app.proxifun.com";
  } else {
    baseUrl = "";
  }

  const axiosInstance = axios.create({
    baseURL: baseUrl, // use with scheme
    timeout: 30000,
    headers: {},
  });

  const getInfos = () => {
    setIsLoading(true);
    const config = {
      method: "post",
      url: "/php/url",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
    };

    axiosInstance
      .request(config)
      .then(function (response) {
        run_script(response.data.items);
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const check_url = (url, id) => {
    const axiosInstance = axios.create({
      timeout: 5000,
    });

    const config = {
      url: url,
    };
    axiosInstance
      .request(config)
      .then(function (response) {
        if (response.status == 200) {
          //console.log("Site ok : " + url);
          setGoodUrl("Ok : " + url);
          //update_activity(url, id);
          upChanges(true, id, url);
          countUrlToChange++;
        }
      })
      .catch(function (error) {
        countUrlError++;
        setGoodUrl("Ko : " + url);
        //console.log("Erreur : " + url + " => pas de changements");
      });
  };

  const check_prefixe_url = (url) => {
    if (url[4] != "s") {
      return true;
    } else {
      countUrlHttps++;
      return false;
    }
  };

  const run_script = (data) => {
    let i;
    let maxLimit = 0;
    if (limit > data.length) {
      maxLimit = data.length;
    } else {
      maxLimit = limit;
    }
    console.log(maxLimit);
    for (i = start; i < maxLimit; i++) {
      if (check_prefixe_url(data[i].website)) {
        let new_url =
          "https" + data[i].website.slice(4, data[i].website.length);
        //console.log(new_url);
        check_url(new_url, data[i].id);
      }
    }
  };

  const update_activity = () => {
    const formData = new FormData();
    formData.append("id", JSON.stringify(changesId));
    formData.append("url", JSON.stringify(changesUrl));
    //console.log(formData);

    const config = {
      method: "post",
      url: "/php/update_website",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    axiosInstance
      .request(config)
      .then(function (response) {
        console.log(response.data);
        response.data ? console.log("Màj ok") : console.log("Erreur màj");
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  const upChanges = (insert, id, url) => {
    if (insert) {
      changesId.push(id);
      changesUrl.push(url);
    } else {
      console.log(changesId);
      console.log(changesUrl);
    }
  };

  return (
    <View style={styles.container}>
      <CustomHeader type={2} navigation={props.navigation} />
      <TouchableOpacity
        style={{ alignSelf: "center", backgroundColor: "green" }}
        onPress={() => getInfos()}
      >
        <Text style={{ color: "#FFF", padding: 10 }}>Run script</Text>
      </TouchableOpacity>
      <View style={styles.separator} />
      {isLoading ? (
        <View>
          <Text>Script started</Text>
          <View style={styles.separator} />
          <Text>{goodUrl}</Text>
          <View style={styles.separator} />
          <Text>
            Nombre d'url analysée :{" "}
            {countUrlError + countUrlHttps + countUrlToChange}/{limit - start}
          </Text>
          <Text>
            Nombre d'url a changer : {countUrlToChange}/{limit - start}
          </Text>
        </View>
      ) : (
        <View>
          <Text>Script end</Text>
        </View>
      )}
      <View style={styles.separator} />
      <TouchableOpacity
        style={{ alignSelf: "center", backgroundColor: "green" }}
        onPress={() => upChanges(false, null, null)}
      >
        <Text style={{ color: "#FFF", padding: 10 }}>See changes</Text>
      </TouchableOpacity>

      <View style={styles.separator} />
      <TouchableOpacity
        style={{ alignSelf: "center", backgroundColor: "green" }}
        onPress={() => update_activity()}
      >
        <Text style={{ color: "#FFF", padding: 10 }}>Update bdd</Text>
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  separator: {
    margin: 10,
  },
});

export default Script;
