import { useState, useEffect } from "react";
import { Dimensions, Platform } from "react-native";
const window = Dimensions.get("window");
const SubContainerStyle = () => {
  const [dimension, setDimension] = useState(window.width);

  useEffect(() => {
    const subscription = Dimensions.addEventListener("change", (window) => {
      setDimension(window.window.width);
    });
    return () => subscription?.remove();
  });

  if (Platform.OS === "ios" || Platform.OS === "android" || dimension < 800) {
    return {
      flex: 1,
      backgroundColor: "#FFFFFF",
    };
  } else {
    return {
      flex: 1,
      width: dimension * 0.8,
      alignSelf: "center",
      backgroundColor: "#FFFFFF",
    };
  }
};

export default SubContainerStyle;
