import React from "react";
import { Text, View, StyleSheet, SafeAreaView } from "react-native";
import styles from "../styles/Styles";
import SubContainerStyle from "../styles/SubContainerStyle";
import CustomHeader from "../components/CustomHeader";

const ErreurPage = (props) => {
  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>
        <Text>
          Aucun résultat dans la catégorie{" "}
          <Text style={styles.txt_bold}>{props.catName}</Text> près de{" "}
          <Text style={styles.txt_bold}>{props.cityName}</Text> pour le moment.
        </Text>
      </View>
    </SafeAreaView>
  );
};

export default ErreurPage;
