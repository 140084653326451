import { View, Image, StyleSheet, TouchableOpacity } from "react-native";
import React, { useState } from "react";
import CustomHaderStyle from "../styles/CustomeHeaderStyle";
import BackSVG from "../Utils/SvgAssets/BackSVG";

const CustomHeader = (props) => {
  const [type, setType] = useState(props.type);

  const renderCustomHeader = () => {
    if (type == 1) {
      return (
        <>
          <View style={styles.btn}></View>
          <View style={styles.logo_container}>
            <Image
              style={{ width: 150, height: 40, alignSelf: "center" }}
              source={require("../assets/logo2022-2.png")}
            />
          </View>
          <View style={styles.btn}></View>
        </>
      );
    } else if (type == 2) {
      return (
        <>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              props.navigation.goBack();
            }}
          >
            <BackSVG />
          </TouchableOpacity>
          <View style={styles.logo_container}>
            <Image
              style={{ width: 150, height: 40, alignSelf: "center" }}
              source={require("../assets/logo2022-2.png")}
            />
          </View>
          <View style={styles.btn}></View>
        </>
      );
    } else if (type == 3) {
      return (
        <>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              props.navigation.navigate("Connexion");
            }}
          >
            <BackSVG />
          </TouchableOpacity>
          <View style={styles.logo_container}>
            <Image
              style={{ width: 150, height: 40, alignSelf: "center" }}
              source={require("../assets/logo2022-2.png")}
            />
          </View>
          <View style={styles.btn}></View>
        </>
      );
    }
  };

  return <View style={CustomHaderStyle()}>{renderCustomHeader()}</View>;
};

const styles = StyleSheet.create({
  logo_container: {
    flex: 4,
  },
  btn: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
});

export default CustomHeader;
