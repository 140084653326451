import { Platform } from "react-native";
import Map from "../../assets/map.svg";
import MapW from "../../assets/map_white.svg";
import MapP from "../../assets/map_pink.svg";

function support() {
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return true;
  } else {
    return false;
  }
}

const MapSVG = (props) => {
  if (support()) {
    if (props.pageId) {
      return (
        <Map
          width={40}
          height={40}
          fill={props.pageId == 2 ? "white" : "#e70181"}
        />
      );
    } else {
      return <Map width={25} height={25} fill={"white"} />;
    }
  } else {
    if (props.pageId) {
      if (props.pageId == 2) {
        return <img src={MapW} width={40} height={40} />;
      } else {
        return <img src={MapP} width={40} height={40} />;
      }
    } else {
      return <img src={Map} width={25} height={25} fill={"white"} />;
    }
  }
};

export default MapSVG;
