import { Text, View, Image, StyleSheet, TouchableOpacity } from "react-native";
import React from "react";

const PubHighlighted = (props) => {
  return (
    <View style={styles.body}>
      <View style={styles.image_container}>
        <Image
          style={styles.image}
          source={{
            uri: "https://www.proxifun.com/wp-content/uploads/2022/05/proxi-pub.jpg",
          }}
        />
      </View>
      <View style={styles.content}>
        <Text style={styles.titre}>Votre annonce ici</Text>
        <View style={styles.separator}></View>
        <Text style={styles.adresse}>
          Vous êtes professionnel et vous désirez recommander votre activité ?
        </Text>
        <View style={styles.separator}></View>
        <Text style={styles.telephone}>Rendez-vous dans l'espace pro !</Text>
        <View style={styles.separator}></View>
        <View style={styles.footer}>
          <TouchableOpacity
            style={styles.btn_web}
            onPress={() =>
              props.navigation.navigate("PubWebView", {
                website: "https://www.app.proxifun.com/html/booster.html",
              })
            }
          >
            <Text style={styles.txt_btn_web}>Plus d'informations</Text>
          </TouchableOpacity>
        </View>
      </View>
    </View>
  );
};

const styles = StyleSheet.create({
  body: {
    height: 200,
    borderRadius: 3,
    paddingVertical: 10,
    borderTopWidth: 3,
    borderBottomWidth: 3,
    borderColor: "gold",
    flexDirection: "row",
  },
  image_container: {
    flex: 1,
    borderRadius: 3,
    marginLeft: 13,
  },
  image: {
    flex: 1,
    borderRadius: 3,
  },
  content: {
    flexDirection: "column",
    justifyContent: "space-between",
    width: "60%",
    paddingHorizontal: 13,
  },
  titre: {
    width: "75%",
    flexWrap: "wrap",
    fontWeight: "bold",
    fontSize: 15,
    alignSelf: "center",
    textAlign: "center",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  separator: {
    margin: 5,
  },
  btn_web: {
    flex: 1,
    backgroundColor: "#e70181",
    borderRadius: 3,
  },
  txt_btn_web: {
    padding: 1,
    alignSelf: "center",
    color: "#FFFFFF",
  },
});

export default PubHighlighted;
