import {
  Text,
  View,
  Platform,
  TouchableOpacity,
  TextInput,
  Alert,
  SafeAreaView,
  StatusBar,
} from "react-native";
import React, { useState, useEffect } from "react";
import * as Crypto from "expo-crypto";
import AwesomeAlert from "react-native-awesome-alerts";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import RadioButtonGroup, { RadioButtonItem } from "expo-radio-button";
import AsyncStorage from "@react-native-async-storage/async-storage";
import Axios from "../Utils/Axios";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import logAnalytics from "../Utils/Analytics";
import WebView from "react-native-webview";

const Connexion = (props) => {
  const [formConnexion, setFormConnexion] = useState(false);
  const [formInscription, setFormInscription] = useState(false);
  const [login, setLogin] = useState(null);
  const [password, setPassword] = useState(null);
  const [alert, setAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState(null);
  const [titleAlert, setTitleAlert] = useState(null);
  const [loading, setLoading] = useState(false);
  const [saveInfos, setSaveInfos] = useState(false);

  useEffect(() => {
    logAnalytics("page", "Connexion");
  }, []);

  useEffect(() => {
    //console.log("recupere infos");
    getPersonnalInfos();
  }, []);

  useEffect(() => {
    if (saveInfos) {
      savePersonnalInfos();
    }
  }, [saveInfos]);

  const verify = () => {
    if (login != null && password != null) {
      return true;
    } else {
      return false;
    }
  };

  const showMsg = (title, message) => {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setAlert(true);
    }
  };

  const checkAccount = () => {
    if (password != null) {
      setLoading(true);

      const formData = new FormData();

      Crypto.digestStringAsync(Crypto.CryptoDigestAlgorithm.SHA512, password)
        .then((hash) => {
          formData.append("login", login);
          formData.append("password", hash);
          const config = {
            method: "post",
            url: "/php/connexion",
            responseType: "json",
            headers: { "Content-Type": "multipart/form-data" },
            transformRequest: (data, headers) => {
              return formData;
            },
            data: formData,
          };

          Axios()
            .request(config)
            .then((response) => {
              //console.log(response.data);
              if (response.data != 0) {
                //Connexion réussie
                //console.log(response.data.items.act_id);
                /*if (saveInfos) {
                  savePersonnalInfos();
                }*/
                setLoading(false);
                if (response.data.items.act_id == -1) {
                  //Admin
                  props.navigation.navigate("Admin", {
                    activity_id: response.data.items.act_id,
                    user_name: response.data.items.user_name,
                    user_id: response.data.items.user_id,
                  });
                } else {
                  //User lambda
                  props.navigation.navigate("DashBoard", {
                    activity_id: response.data.items.act_id,
                    user_name: response.data.items.user_name,
                    user_id: response.data.items.user_id,
                  });
                }
              } else {
                setLoading(false);
                showMsg(
                  "Connexion échouée",
                  "Veuillez vérifier votre login et/ou votre mot de passe"
                );
              }
            })
            .catch((error) => {
              //console.log(error);
              setLoading(false);
              showMsg(
                "Connexion échouée",
                "Veuillez vérifier votre login et/ou votre mot de passe"
              );
            });
        })
        .catch((error) => console.log(error));
    }
  };

  const saveUser = () => {
    if (verify()) {
      const formData = new FormData();

      Crypto.digestStringAsync(
        Crypto.CryptoDigestAlgorithm.SHA512,
        password
      ).then((hash) => {
        formData.append("login", login);
        formData.append("password", hash);
        const config = {
          method: "post",
          url: "/php/save",
          responseType: "json",
          headers: { "Content-Type": "multipart/form-data" },
          transformRequest: (data, headers) => {
            return formData;
          },
          data: formData,
        };

        Axios()
          .request(config)
          .then((response) => {
            if (response.data != 0) {
              //Connexion réussie
              //console.log(response.data);
              props.navigation.navigate("TakeActivity", {
                user_name: login,
                user_id: response.data,
              });
            } else {
              showMsg(
                "Nom d'utilisateur déjà utilisé",
                "Veuillez saisir un email/login différent"
              );
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    }
  };

  const savePersonnalInfos = () => {
    AsyncStorage.setItem("@login", login)
      .then(() => {
        //console.log("success login");
        AsyncStorage.setItem("@passwrd", password)
          .then(() => {
            //console.log("success password");
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPersonnalInfos = () => {
    AsyncStorage.getItem("@login")
      .then((log) => {
        setLogin(log);
        AsyncStorage.getItem("@passwrd")
          .then((pass) => {
            setPassword(pass);
            checkAccount();
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  const hideAlert = () => {
    setAlert(false);
  };

  const renderFormConnexion = () => {
    if (formConnexion) {
      if (loading) {
        return <ActivityIndicatorElement />;
      } else {
        return (
          <View style={styles.margin}>
            <Text>Nom d'utilisateur :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setLogin}
              value={login}
            />
            <Text>Mot de passe :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setPassword}
              value={password}
              secureTextEntry={true}
            />
            <RadioButtonGroup
              containerStyle={{ marginBottom: 10 }}
              selected={saveInfos}
              onSelected={() => {
                if (saveInfos) {
                  setSaveInfos(false);
                } else {
                  setSaveInfos(true);
                }
              }}
              radioBackground="green"
            >
              <RadioButtonItem
                value={true}
                label="Enregistrer mes informations"
              />
            </RadioButtonGroup>
            <TouchableOpacity
              style={styles.btn}
              onPress={() => {
                if (verify()) {
                  checkAccount();
                } else {
                  setLoading(false);
                  showMsg(
                    "Echec lors de la connexion",
                    "Veuillez remplir tous les champs"
                  );
                }
              }}
            >
              <Text style={styles.text_btn}>Se connecter</Text>
            </TouchableOpacity>
            <AwesomeAlert
              show={alert}
              showProgress={false}
              title={titleAlert}
              message={msgAlert}
              closeOnTouchOutside={true}
              showConfirmButton={true}
              confirmText="Ok"
              confirmButtonColor="#e70181"
              onConfirmPressed={() => {
                hideAlert();
              }}
            />
          </View>
        );
      }
    }
  };

  const renderFormInscription = () => {
    if (formInscription) {
      if (loading) {
        return <ActivityIndicatorElement />;
      } else {
        return (
          <View style={styles.margin}>
            <Text>
              Vous êtes nouveau sur Proxifun ? Laisser nous vous guider pas à
              pas
            </Text>
            <View style={styles.separator} />
            <Text>
              Commencez par choisir un nom d'utlisateur (votre mail par exemple)
              et un mot de passe
            </Text>
            <View style={styles.separator} />
            <Text>Nom d'utilisateur :</Text>
            <TextInput style={styles.input} onChangeText={setLogin} />
            <Text>Mot de passe :</Text>
            <TextInput
              style={styles.input}
              onChangeText={setPassword}
              secureTextEntry={true}
            />
            <TouchableOpacity
              style={styles.btn}
              onPress={() => {
                if (verify()) {
                  saveUser();
                } else {
                  setLoading(false);
                  showMsg(
                    "Echec lors de l'inscription",
                    "Veuillez remplir tous les champs"
                  );
                }
              }}
            >
              <Text style={styles.text_btn}>S'inscrire</Text>
            </TouchableOpacity>
            <AwesomeAlert
              show={alert}
              showProgress={false}
              title={titleAlert}
              message={msgAlert}
              closeOnTouchOutside={true}
              showConfirmButton={true}
              confirmText="Ok"
              confirmButtonColor="#e70181"
              onConfirmPressed={() => {
                hideAlert();
              }}
            />
          </View>
        );
      }
    }
  };

  const renderForm = () => {
    return (
      <>
        <View style={styles.separator} />
        <View style={{ flexDirection: "row", justifyContent: "space-around" }}>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              setFormConnexion(true);
              setFormInscription(false);
            }}
          >
            <Text style={styles.text_btn}>Connexion</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={styles.btn}
            onPress={() => {
              setFormInscription(true);
              setFormConnexion(false);
            }}
          >
            <Text style={styles.text_btn}>Inscription</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.separator} />
        {renderFormConnexion()}
        {renderFormInscription()}
        {formConnexion == false && formInscription == false ? (
          <WebView
            showsVerticalScrollIndicator={false}
            style={{ flex: 1 }}
            source={{
              uri: "https://www.app.proxifun.com/html/espace-pro.html",
            }}
            javaScriptEnabled={true}
            domStorageEnabled={true}
            onMessage={(event) => {}}
            ref={() => {}}
          />
        ) : null}
      </>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderForm()}</View>
    </SafeAreaView>
  );
};

export default Connexion;
