import axios from "axios";
import BaseUrl from "../Utils/BaseUrl";

const Axios = () => {
  const axiosInstance = axios.create({
    baseURL: BaseUrl(),
    timeout: 30000,
    headers: {},
  });
  return axiosInstance;
};

export default Axios;
