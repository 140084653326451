import { StyleSheet, View, TouchableOpacity, Image } from "react-native";
import MapSVG from "../Utils/SvgAssets/MapSVG";
import BurgerSVG from "../Utils/SvgAssets/BurgerSVG";
import logAnalytics from "../Utils/Analytics";

const TabBar = (props) => {
  function focusStyleBtn() {
    return {
      width: "45%",
      alignSelf: "center",
      alignItems: "center",
      backgroundColor: "#e70181",
      borderRadius: 15,
      margin: 5,
    };
  }

  function notFocusStyleBtn() {
    return {
      width: "45%",
      alignSelf: "center",
      alignItems: "center",
      backgroundColor: "#FFFFFF",
      borderRadius: 15,
      margin: 5,
    };
  }

  return (
    <View style={styles.tabbar_container}>
      <TouchableOpacity
        style={props.pageId == 1 ? focusStyleBtn() : notFocusStyleBtn()}
        onPress={() => {
          if (props.pageId != 1) {
            // 1. Navigate to the Activities route with params
            props.navigation.navigate("Activities", {
              categoryName: props.categoryName,
              categoryId: props.categoryId,
              city: props.city,
              latitude: props.latitude,
              longitude: props.longitude,
              navigation: props.navigation,
            });
            logAnalytics("vue_activite", "liste");
          }
        }}
      >
        <BurgerSVG pageId={props.pageId} />
      </TouchableOpacity>
      <TouchableOpacity
        style={props.pageId == 2 ? focusStyleBtn() : notFocusStyleBtn()}
        onPress={() => {
          if (props.pageId != 2) {
            // 1. Navigate to the Mapview route with params
            props.navigation.navigate("Mapview", {
              categoryName: props.categoryName,
              categoryId: props.categoryId,
              city: props.city,
              latitude: props.latitude,
              longitude: props.longitude,
              navigation: props.navigation,
              data: props.data,
            });
            logAnalytics("vue_activite", "carte");
          }
        }}
      >
        <MapSVG pageId={props.pageId} />
      </TouchableOpacity>
    </View>
  );
};

const styles = StyleSheet.create({
  tabbar_container: {
    position: "absolute",
    bottom: 20,
    left: 20,
    right: 20,
    elevation: 0,
    backgroundColor: "#FFFFFF",
    borderRadius: 15,
    flexDirection: "row",
    //height: '7%',
    //borderWidth: 1,
    justifyContent: "space-around",
    shadowColor: "#e70181",
    shadowOffset: {
      width: 10,
      height: 10,
    },
    shadowOpacity: 0.25,
    shadowRadius: 15,
    elevation: 5,
  },
});

export default TabBar;
