import {
  Text,
  View,
  Image,
  StyleSheet,
  TouchableOpacity,
  Platform,
  Linking,
  ScrollView,
  SafeAreaView,
  StatusBar,
} from "react-native";
import React, { useEffect } from "react";
import SubContainerStyle from "../styles/SubContainerStyle";
import styles from "../styles/Styles";
import CustomHeader from "../components/CustomHeader";
import logAnalytics from "../Utils/Analytics";

const Details = (props) => {
  useEffect(() => {
    logAnalytics("page", "Details");
  }, []);

  const check_website = () => {
    if (props.route.params.website.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const openMap = async () => {
    const provider = Platform.OS === "ios" ? "apple" : "google";
    const link = `http://maps.${provider}.com/?daddr=${props.route.params.activityName} ${props.route.params.adresse}`;

    try {
      const supported = await Linking.canOpenURL(link);
      if (supported) Linking.openURL(link);
    } catch (error) {
      console.log(error);
    }
  };

  const renderDetails = () => {
    return (
      <>
        <Text style={specStyles.name}>{props.route.params.activityName}</Text>
        <Image
          style={specStyles.image}
          source={{ uri: props.route.params.imageurl }}
        />
        <ScrollView style={specStyles.scrollview}>
          <Text>{props.route.params.content}</Text>
          <View style={specStyles.separator} />
          <Text>{props.route.params.adresse}</Text>
          <View style={specStyles.separator} />
          <Text>{props.route.params.telephone}</Text>
          <View style={specStyles.separator} />
          <View style={specStyles.footer}>
            <TouchableOpacity style={specStyles.btn} onPress={() => openMap()}>
              <Text style={specStyles.txt_btn}>Itinéraire</Text>
            </TouchableOpacity>
            {check_website() ? (
              <TouchableOpacity
                style={specStyles.btn_web}
                onPress={() =>
                  props.navigation.navigate("Webview", {
                    website: props.route.params.website,
                  })
                }
              >
                <Text style={specStyles.txt_btn_web}>Site web</Text>
              </TouchableOpacity>
            ) : null}
          </View>
        </ScrollView>
      </>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={2} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderDetails()}</View>
    </SafeAreaView>
  );
};

const specStyles = StyleSheet.create({
  container: {
    flex: 1,
    //borderWidth: 1
  },
  scrollview: {
    flex: 1,
    margin: 10,
  },
  name: {
    alignSelf: "center",
    fontWeight: "bold",
    fontSize: 18,
    margin: 10,
  },
  image: {
    height: "30%",
  },
  footer: {
    flexDirection: "row",
    justifyContent: "space-around",
  },
  separator: {
    margin: 5,
  },
  btn: {
    flex: 1,
    margin: 2,
    backgroundColor: "#eee",
    borderRadius: 3,
  },
  btn_web: {
    flex: 1,
    margin: 2,
    backgroundColor: "#e70181",
    borderRadius: 3,
  },
  txt_btn: {
    alignSelf: "center",
  },
  txt_btn_web: {
    alignSelf: "center",
    color: "#FFFFFF",
  },
});

export default Details;
