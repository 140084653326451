import React from "react";
import { View, ActivityIndicator, StyleSheet } from "react-native";

const ActivityIndicatorElement = () => {
  return (
    <View style={styles.activityIndicatorStyle}>
      <ActivityIndicator color="#009688" size="large" />
    </View>
  );
};

const styles = StyleSheet.create({
  activityIndicatorStyle: {
    flex: 1,
    alignSelf: "center",
    justifyContent: "center",
  },
});

export default ActivityIndicatorElement;
