import React, { useState, useEffect } from "react";
import {
  Text,
  View,
  TouchableOpacity,
  TextInput,
  SafeAreaView,
  StatusBar,
  Image,
  StyleSheet,
  Alert,
  Platform,
} from "react-native";
import * as Location from "expo-location";
import Category from "../components/Category";
import styles from "../styles/Styles";
import SubContainerStyle from "../styles/SubContainerStyle";
import BurgerSVG from "../Utils/SvgAssets/BurgerSVG";
import BrowSVG from "../Utils/SvgAssets/BrowSVG";
import LocationSVG from "../Utils/SvgAssets/LocationSVG";
import CustomHaderStyle from "../styles/CustomeHeaderStyle";
import logAnalytics from "../Utils/Analytics";
import AwesomeAlert from "react-native-awesome-alerts";
import * as Analytics from "expo-firebase-analytics";

const Home = (props) => {
  /**
   * statut 0 => La localisation est activée
   * statut 1 => La localisation est désactivée
   */
  /**
   * type 0 => Geolocalisation
   * type 1 => Recherche
   */
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [city, setCity] = useState(null);
  const [searchedCity, setSearchedCity] = useState(null);
  const [statut, setStatut] = useState(false);
  const [showSearchBar, setShowSearchBar] = useState(true);
  const [type, setType] = useState(null);
  const [alert, setAlert] = useState(false);
  const [msgAlert, setMsgAlert] = useState(null);
  const [titleAlert, setTitleAlert] = useState(null);
  const [analytics, setAnalytics] = useState(false);

  useEffect(() => {
    getPermissions();
  }, []);

  useEffect(() => {
    showMsg(
      "Autoriser Proxifun à suivre vos activités dans l'application",
      "Vos données anonymisées seront utilisées à des fins d'analyse dans le but d'améliorer l'experience utilisateur"
    );
  }, []);

  useEffect(() => {
    if (analytics) {
      logAnalytics("page", "Home");
    }
  }, [analytics]);

  useEffect(() => {
    if (statut == 0) {
      setType(0);
    } else if (statut == 1) {
      setType(1);
    }
  }, [statut]);

  useEffect(() => {
    setShowSearchBar(true);
  }, [type]);

  async function setAnalyticsTrue() {
    await Analytics.setAnalyticsCollectionEnabled(true);
  }

  async function setAnalyticsFalse() {
    await Analytics.setAnalyticsCollectionEnabled(false);
  }

  function showMsg(title, message) {
    if (Platform.OS === "android" || Platform.OS === "ios") {
      Alert.alert(title, message, [
        {
          text: "Non",
          onPress: () => {
            setAnalyticsFalse();
            setAnalytics(false);
          },
          style: "cancel",
        },
        {
          text: "Oui",
          onPress: () => {
            setAnalyticsTrue();
            setAnalytics(true);
          },
        },
      ]);
    } else {
      setTitleAlert(title);
      setMsgAlert(message);
      setAlert(true);
    }
  }

  const hideAlert = () => {
    setAlert(false);
  };

  async function getPermissions() {
    let { status } = await Location.requestForegroundPermissionsAsync();
    if (status !== "granted") {
      setStatut(1);
      return;
    } else {
      Location.setGoogleApiKey("AIzaSyD2QFnwIP64KIaG9mKpIMnwkqpX3fiKtZQ");
      let location = await Location.getCurrentPositionAsync();
      if (location != null) {
        getCity(location.coords["latitude"], location.coords["longitude"]);
      }
    }
  }

  async function getCity(lat, long) {
    let regionName = await Location.reverseGeocodeAsync({
      latitude: lat,
      longitude: long,
    });
    //console.log(regionName[0]['city']);
    setLatitude(lat);
    setLongitude(long);
    setCity(regionName[0]["city"]);
    setStatut(0);
  }

  function getCoords(searchedCity) {
    Location.setGoogleApiKey("AIzaSyD2QFnwIP64KIaG9mKpIMnwkqpX3fiKtZQ");
    Location.geocodeAsync(searchedCity)
      .then((coords) => {
        let lat = coords[0].latitude.toFixed(5);
        let long = coords[0].longitude.toFixed(5);
        setLatitude(lat);
        setLongitude(long);
        setShowSearchBar(false);
        //console.log(searchedCity);
      })
      .catch((error) => console.log(error));
  }

  const renderHome = () => {
    switch (statut) {
      case 0:
        if (type == 0) {
          if (city == null) {
            return (
              <View>
                <View style={styles.separator} />
                <Text style={styles.text}>Localisation en cours...</Text>
              </View>
            );
          } else if (city != null) {
            return (
              <Category
                navigation={props.navigation}
                latitude={latitude}
                longitude={longitude}
                city={city}
              />
            );
          }
        } else if (type == 1 && showSearchBar) {
          return (
            <>
              <View style={{ flexDirection: "row" }}>
                <Text style={stylesHeader.text}>Ville :</Text>
                <TextInput
                  style={stylesHeader.input}
                  onChangeText={setSearchedCity}
                  placeholder=" Paris"
                />
              </View>
              <TouchableOpacity
                style={stylesHeader.submit_btn}
                onPress={() => {
                  getCoords(searchedCity);
                }}
              >
                <Text style={styles.text_btn}>Rechercher</Text>
              </TouchableOpacity>
              <View style={styles.XSseparator} />
              <Category
                navigation={props.navigation}
                latitude={latitude}
                longitude={longitude}
                city={searchedCity}
              />
            </>
          );
        } else if (type == 1 && !showSearchBar) {
          return (
            <Category
              navigation={props.navigation}
              latitude={latitude}
              longitude={longitude}
              city={searchedCity}
            />
          );
        }
        break;
      case 1:
        if (type == 0) {
          return (
            <>
              <Text>
                La géolocalisation est déactivée sur votre appareil, veuillez
                saisir la ville manuellement.
              </Text>
            </>
          );
        } else if (type == 1 && showSearchBar) {
          return (
            <>
              <View style={styles.separator} />
              <Text style={styles.text}>
                La géolocalisation est désactivée sur votre appareil. Veuillez
                saisir la ville manuellement.
              </Text>
              <View style={styles.separator} />
              <Text style={styles.text}>Ville :</Text>
              <TextInput
                style={styles.input}
                onChangeText={setSearchedCity}
                placeholder=" Paris"
              />
              <TouchableOpacity
                style={styles.submit_btn}
                onPress={() => {
                  getCoords(searchedCity);
                }}
              >
                <Text style={styles.text_btn}>Rechercher</Text>
              </TouchableOpacity>
            </>
          );
        } else if (type == 1 && !showSearchBar) {
          return (
            <Category
              navigation={props.navigation}
              latitude={latitude}
              longitude={longitude}
              city={searchedCity}
            />
          );
        }
        break;
    }
  };

  function switchType() {
    if (type == 1) {
      setType(0);
      logAnalytics("type_recherche", "Géolocalisation");
    } else {
      setType(1);
      logAnalytics("type_recherche", "Manuelle");
    }
  }

  const renderHomeHeader = () => {
    return (
      <View style={CustomHaderStyle()}>
        <TouchableOpacity
          style={stylesHeader.btn}
          onPress={() => {
            props.navigation.navigate("Menu");
          }}
        >
          <BurgerSVG />
        </TouchableOpacity>
        <View style={stylesHeader.logo_container}>
          <Image
            style={{ width: 150, height: 40, alignSelf: "center" }}
            source={require("../assets/logo2022-2.png")}
          />
        </View>
        <View style={stylesHeader.btn}>
          <TouchableOpacity
            style={stylesHeader.btn}
            onPress={() => {
              switchType();
            }}
          >
            {type == 0 ? <BrowSVG /> : <LocationSVG />}
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      {renderHomeHeader()}
      <View style={SubContainerStyle()}>
        {renderHome()}
        <AwesomeAlert
          show={alert}
          showProgress={false}
          title={titleAlert}
          message={msgAlert}
          closeOnTouchOutside={true}
          showConfirmButton={true}
          showCancelButton={true}
          confirmText="Oui"
          cancelText="Non"
          confirmButtonColor="#e70181"
          onCancelPressed={() => {
            hideAlert();
            setAnalyticsFalse();
            setAnalytics(false);
          }}
          onConfirmPressed={() => {
            hideAlert();
            setAnalyticsTrue();
            setAnalytics(true);
          }}
        />
      </View>
    </SafeAreaView>
  );
};

const stylesHeader = StyleSheet.create({
  logo_container: {
    flex: 4,
  },
  btn: {
    flex: 1,
    alignSelf: "center",
    alignItems: "center",
    justifyContent: "center",
  },
  input: {
    margin: 10,
    backgroundColor: "#C8C8C8",
    borderRadius: 5,
    padding: 5,
    flex: 8,
  },
  submit_btn: {
    alignSelf: "center",
    borderRadius: 5,
  },
  text: {
    fontSize: 15,
    flex: 1,
    alignSelf: "center",
    marginLeft: 10,
  },
});

export default Home;
