import {
  Text,
  View,
  TouchableOpacity,
  SafeAreaView,
  StatusBar,
} from "react-native";
import React, { useState, useEffect } from "react";
import { useFocusEffect } from "@react-navigation/native";
import Item from "../components/Item";
import HighlightedItem from "../components/HighlightedItem";
import ActivityIndicatorElement from "../components/ActivityIndicator";
import CustomHeader from "../components/CustomHeader";
import styles from "../styles/Styles";
import SubContainerStyle from "../styles/SubContainerStyle";
import Axios from "../Utils/Axios";
import logAnalytics from "../Utils/Analytics";

const FormData = global.FormData;

const DashBoard = (props) => {
  const [nom, setNom] = useState(null);
  const [adress, setAdress] = useState(null);
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [telephone, setTelephone] = useState(null);
  const [mail, setMail] = useState(null);
  const [content, setContent] = useState(null);
  const [website, setWebsite] = useState(null);
  const [picture, setPicture] = useState(null);
  const [highlighted, setHighlighted] = useState(0);
  const [startHighlighted, setStartHighlighted] = useState(null);
  const [endHighlighted, setEndHighlighted] = useState(null);
  const [showItem, setShowItem] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    logAnalytics("page", "Tableau de bord");
  }, []);

  useFocusEffect(
    React.useCallback(() => {
      getMyActivity();

      return () => {};
    }, [])
  );

  function getMyActivity() {
    const formData = new FormData();
    formData.append("type", "1");
    formData.append("id", props.route.params.activity_id);

    const config = {
      method: "post",
      url: "/php/search",
      responseType: "json",
      headers: { "Content-Type": "multipart/form-data" },
      transformRequest: (data, headers) => {
        return formData;
      },
      data: formData,
    };
    setLoading(true);
    Axios()
      .request(config)
      .then((response) => {
        //console.log(response.data);
        if (response.data.items != null) {
          createActivity(response.data);
        }
      })
      .catch(function (error) {
        console.log(error);
      });
    //console.log(props.route.params.activity_id);
  }

  const createActivity = (data) => {
    if (data != null) {
      setNom(data.items[0]["title"]);
      setAdress(data.items[0]["address"]);
      setContent(data.items[0]["content"]);
      setMail(data.items[0]["email"]);
      setHighlighted(data.items[0]["highlighted"]);
      setLatitude(data.items[0]["latitude"]);
      setLongitude(data.items[0]["longitude"]);
      setTelephone(data.items[0]["phoneNumber"]);
      setPicture(data.items[0]["picture"]);
      setWebsite(data.items[0]["website"]);
      if (
        data.items[0]["start_highlighted"] != null &&
        data.items[0]["end_highlighted"] != null
      ) {
        setStartHighlighted(data.items[0]["start_highlighted"]);
        setEndHighlighted(data.items[0]["end_highlighted"]);
      }
      setShowItem(true);
      setLoading(false);
    }
  };

  const renderMyItem = () => {
    if (loading) {
      return <ActivityIndicatorElement />;
    } else if (showItem) {
      return (
        <View style={{ height: "25%" }}>
          {highlighted == 1 ? (
            <View style={{ flex: 1 }}>
              <HighlightedItem
                id={props.route.params.activity_id}
                titre={nom}
                adresse={adress}
                telephone={telephone}
                mail={mail}
                content={content}
                website={website}
                imageurl={picture}
                navigation={props.navigation}
              />
              <View style={styles.XSseparator} />
            </View>
          ) : (
            <Item
              id={props.route.params.activity_id}
              titre={nom}
              adresse={adress}
              telephone={telephone}
              mail={mail}
              content={content}
              website={website}
              imageurl={picture}
              navigation={props.navigation}
            />
          )}
        </View>
      );
    }
  };

  const renderDashBoard = () => {
    if (loading) {
      return <ActivityIndicatorElement />;
    } else if (showItem) {
      return (
        <View style={styles.margin}>
          <Text style={{ textAlign: "center", fontWeight: "bold" }}>
            Bienvenue sur votre dashboard
          </Text>

          <View style={styles.XSseparator} />
          <Text>Votre activité : </Text>
          <View style={styles.XSseparator} />
          {renderMyItem()}

          {highlighted == 1 ? (
            <>
              <Text>Date début de recommandation : {startHighlighted}</Text>
              <Text>Date fin de recommandation : {endHighlighted}</Text>
            </>
          ) : null}
          <View style={styles.separator} />
          <TouchableOpacity
            style={styles.btn}
            onPress={() =>
              props.navigation.navigate("Edit", {
                type_edit: 2,
                id: props.route.params.activity_id,
                nom: nom,
                telephone: telephone,
                mail: mail,
                adress: adress,
                latitude: latitude,
                longitude: longitude,
                content: content,
                website: website,
                highlighted: highlighted,
              })
            }
          >
            <Text style={styles.text_btn}>Modifier</Text>
          </TouchableOpacity>
          <View style={styles.separator} />
          <Text>
            Vous souhaitez placer votre activité sur le devant de la scène ?
          </Text>
          <View style={styles.separator} />
          <Text>Recommander votre activité dès maintenant !</Text>
          <View style={styles.separator} />
          {renderChoice()}
        </View>
      );
    }
  };

  const renderChoice = () => {
    return (
      <View>
        <View style={{ flexDirection: "row", padding: 5 }}>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>1 mois</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>99€</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignSelf: "center", borderRadius: 5, flex: 2 }}
            onPress={() => {
              props.navigation.navigate("Payment", {
                id: props.route.params.activity_id,
                product: 0,
              });
            }}
          >
            <Text style={styles.text_btn}>COMMANDER</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.separator} />
        <View
          style={{
            flexDirection: "row",
            padding: 5,
          }}
        >
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>1 trimestre</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>249€</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignSelf: "center", borderRadius: 5, flex: 2 }}
            onPress={() => {
              props.navigation.navigate("Payment", {
                id: props.route.params.activity_id,
                product: 1,
              });
            }}
          >
            <Text style={styles.text_btn}>COMMANDER</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.separator} />
        <View style={{ flexDirection: "row", padding: 5 }}>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>1 semestre</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>449€</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignSelf: "center", borderRadius: 5, flex: 2 }}
            onPress={() => {
              props.navigation.navigate("Payment", {
                id: props.route.params.activity_id,
                product: 2,
              });
            }}
          >
            <Text style={styles.text_btn}>COMMANDER</Text>
          </TouchableOpacity>
        </View>
        <View style={styles.separator} />
        <View style={{ flexDirection: "row", padding: 5 }}>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>1 an</Text>
          </TouchableOpacity>
          <TouchableOpacity style={{ alignSelf: "center", flex: 2 }}>
            <Text style={{ alignSelf: "center" }}>599€</Text>
          </TouchableOpacity>
          <TouchableOpacity
            style={{ alignSelf: "center", borderRadius: 5, flex: 2 }}
            onPress={() => {
              props.navigation.navigate("Payment", {
                id: props.route.params.activity_id,
                product: 3,
              });
            }}
          >
            <Text style={styles.text_btn}>COMMANDER</Text>
          </TouchableOpacity>
        </View>
      </View>
    );
  };

  return (
    <SafeAreaView style={styles.main_container}>
      <StatusBar backgroundColor={"#e70181"} />
      <CustomHeader type={3} navigation={props.navigation} />
      <View style={SubContainerStyle()}>{renderDashBoard()}</View>
    </SafeAreaView>
  );
};

export default DashBoard;
