import { Platform } from "react-native";
import Brow from "../../assets/brow_white.svg";

function support() {
  if (Platform.OS === "ios" || Platform.OS === "android") {
    return true;
  } else {
    return false;
  }
}

const BrowSVG = () => {
  if (support()) {
    return <Brow width={25} height={25} fill={"white"} />;
  } else {
    return <img src={Brow} width={25} height={25} />;
  }
};

export default BrowSVG;
